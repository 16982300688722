import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';
import { PageTypeEnum } from '../../insightx-utils/enums/page-type.enum';
import { IItem } from '../../insightx-utils/types/item.type';
import { IShelfConfig } from '../../insightx-utils/types/shelf-config.type';
import { IShelf } from '../../insightx-utils/types/shelf.type';
import { postItemsSearch } from '../../services/shelf-previewer/post-items-search';
import { postRecommends } from '../../services/shelf-previewer/post-recommends';
import { scrapeListPage } from '../../services/shelf-previewer/scrape-list-page';
import { useClient } from '../../store/client.store';
import { IScrapedListPage } from '../../types/list-page.type';
import Shelf from '../shelf/Shelf';

interface ListPageCardProps {
  cardIndex: number;
  urls: string[];
  selectedUrl: string | null;
  onUrlSelect: (cardIndex: number, url: string) => void;
  shelfConfig: IShelfConfig;
}

const CardGrid = styled.div`
  display: flex;
  gap: 20px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;

  /* スクロールバーを非表示にするスタイル */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

const SmartphoneFrame = styled.div`
  width: 100%;
  height: 800px;
  border: 16px solid #ddd;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  overflow-y: auto;
  position: relative;
  background: white;

  /* スクロールバーを非表示にするスタイル */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    background: #9e9e9e;
    border-radius: 10px;
  }
`;

const ShelvesContainer = styled.div`
  padding: 10px;
  height: 100%;
  overflow-y: auto;

  /* スクロールバーを非表示にするスタイル */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

const ListPageCard: React.FC<ListPageCardProps> = ({
  cardIndex,
  urls,
  selectedUrl,
  onUrlSelect,
  shelfConfig,
}) => {
  const { client: clientState } = useClient();
  const sessionRef = useRef<string>(
    `session-${Math.random().toString(36).substr(2, 9)}`,
  );

  // Scraped List Page
  const {
    data: scrapedListPage,
    isLoading: isScraping,
    error: scrapeError,
  } = useQuery<IScrapedListPage>({
    queryKey: ['scrapedListPage', selectedUrl],
    queryFn: () => scrapeListPage(selectedUrl!),
    enabled: !!selectedUrl,
    retry: false,
  });

  // Items
  const {
    data: items,
    isLoading: isItemsLoading,
    error: itemsError,
  } = useQuery<IItem[]>({
    queryKey: ['items', scrapedListPage?.aids],
    queryFn: () =>
      postItemsSearch({
        clientId: clientState.selectedClient.clientId,
        aids: scrapedListPage!.aids,
      }).then((res) => {
        const itemsList: IItem[] = [];
        for (const aid of scrapedListPage!.aids) {
          if (res.items[aid]) {
            itemsList.push(...res.items[aid]);
          }
        }
        return itemsList;
      }),
    enabled: !!scrapedListPage,
    retry: false,
  });

  // Recommendations
  const {
    data: recommendations,
    isLoading: isRecommendationsLoading,
    error: recommendationsError,
  } = useQuery<IShelf[]>({
    queryKey: ['recommendations', scrapedListPage?.aids, shelfConfig],
    queryFn: () =>
      postRecommends({
        clientId: clientState.selectedClient.clientId,
        session: sessionRef.current,
        pageType: PageTypeEnum.Pdp,
        seedAids: scrapedListPage!.aids,
        shelfConfig,
      }).then((res) => res.shelves),
    enabled: !!scrapedListPage && !!shelfConfig,
    retry: false,
  });

  const isLoading = isScraping || isItemsLoading || isRecommendationsLoading;
  const error = scrapeError || itemsError || recommendationsError;

  const handleChange = (event: SelectChangeEvent<string>) => {
    onUrlSelect(cardIndex, event.target.value);
  };

  const handleOpenUrl = () => {
    if (selectedUrl) {
      window.open(selectedUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Card>
      <CardContent>
        <CardGrid>
          <LeftColumn>
            <FormControl fullWidth>
              <InputLabel>URLを選択</InputLabel>
              <Select
                value={selectedUrl || ''}
                onChange={handleChange}
                label="URLを選択"
              >
                {urls.map((url, idx) => (
                  <MenuItem key={idx} value={url}>
                    {url}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {scrapedListPage && (
              <Box mt={2}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: '16px', fontWeight: 'bold' }}
                >
                  {scrapedListPage.title} (ページ数: {scrapedListPage.maxPages})
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '12px', color: 'rgb(100, 100, 100)' }}
                >
                  {scrapedListPage.pageTitle}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={handleOpenUrl}
                  sx={{ mt: 1, marginBottom: '5px', padding: '5px 10px' }}
                >
                  リンクを開く
                </Button>
              </Box>
            )}
          </LeftColumn>
          <RightColumn>
            <SmartphoneFrame>
              <ShelvesContainer>
                {isLoading ? (
                  <CircularProgress />
                ) : error ? (
                  <Typography color="error">エラーが発生しました。</Typography>
                ) : (
                  <>
                    {items && items.length > 0 && (
                      <Shelf
                        shelf={{
                          items,
                          tagline2: '🔍 検索結果',
                          shelfId: `search-results-${cardIndex}`,
                          shelfType: ShelfTypeEnum.related,
                          sliderRows: 2,
                        }}
                      />
                    )}
                    {recommendations &&
                      recommendations.map((shelf, index) => (
                        <Shelf key={index} shelf={shelf} />
                      ))}
                  </>
                )}
              </ShelvesContainer>
            </SmartphoneFrame>
          </RightColumn>
        </CardGrid>
      </CardContent>
    </Card>
  );
};

export default ListPageCard;
