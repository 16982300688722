export const ConfigUtil = {
  getGoogleCliendId: () => {
    if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
      throw new Error('REACT_APP_GOOGLE_CLIENT_ID is not set');
    }
    return process.env.REACT_APP_GOOGLE_CLIENT_ID;
  },

  getBackendUrl: () => {
    if (!process.env.REACT_APP_BACKEND_URL) {
      throw new Error('REACT_APP_BACKEND_URL is not set');
    }
    return process.env.REACT_APP_BACKEND_URL;
  },
};
