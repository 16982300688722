import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearAuthHeaders } from '../services/api';
import { fetchUserDetails } from '../services/user-service';
import { useAuth } from '../store/auth.store';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { login, logout } = useAuth();
  const [isInitializing, setIsInitializing] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const storedToken = localStorage.getItem('ix_dashboard_token');

        if (storedToken && location.pathname !== '/login') {
          try {
            const updatedUserDetails = await fetchUserDetails();
            login(updatedUserDetails, storedToken);
          } catch (error) {
            console.error('Token validation failed:', error);
            handleLogout();
          }
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        handleLogout();
      } finally {
        setIsInitializing(false);
      }
    };

    initializeAuth();
  }, [login, logout, navigate, location.pathname]);

  const handleLogout = () => {
    clearAuthHeaders();
    localStorage.removeItem('ix_dashboard_user');
    localStorage.removeItem('ix_dashboard_token');
    logout();
    navigate('/login');
  };

  if (isInitializing && location.pathname !== '/login') {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return <>{children}</>;
};
