import type { IPostRecommendsReqDto } from '../../dto/post-recommends.dto';
import type { IShelfGroup } from '../../types/shelf-group.type';
import type { IShelf } from '../../types/shelf.type';
import type { IConfig } from '../config/config.interface';
import type { IDomService } from '../dom/dom.service.interface';
import type { IFetchService } from '../fetch/fetch.service.interface';
import { IPreprocessService } from '../preprocess/preprocess.service.interface';

export class ShelfService {
  private domService: IDomService;
  private fetchService: IFetchService;
  private preprocessService: IPreprocessService;

  public config: IConfig;
  public shelfGroups: Record<string, IShelfGroup> = {};
  public shelves: IShelf[] = [];
  public dto: IPostRecommendsReqDto = {};

  constructor(
    config: IConfig,
    domService: IDomService,
    fetchService: IFetchService,
    preprocessService: IPreprocessService,
  ) {
    this.config = config;
    this.domService = domService;
    this.fetchService = fetchService;
    this.preprocessService = preprocessService;
  }

  async bootstrap() {
    // configの初期化
    await this.config.init();

    await Promise.all([
      // シェルフ描画用のDOMを挿入
      this.insertDoms(),

      // シェルフ情報を取得
      this.fetchShelves(),
    ]);
  }

  async insertDoms() {
    await this.domService.insertDoms();
    await this.domService.initShelfAreaShown();
  }

  async fetchShelves() {
    ({ shelves: this.shelves, dto: this.dto } =
      await this.fetchService.fetchShelves());

    // シェルフ情報を前処理
    this.shelves = await this.preprocessService.preprocessShelves(this.shelves);

    // シェルフをグルーピング
    this.shelfGroups = await this.preprocessService.groupShelves(this.shelves);
  }
}
