import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { ChevronDown, ChevronRight } from 'lucide-react';
import React from 'react';
import { columns } from './constants';
import { MetricsRow } from './MetricsRow';
import { ShelfTypeGroupProps } from './types';
import {
  calculateMonthlyRevenue,
  calculateSummaryMetrics,
  safeNumber,
} from './utils';

export const ShelfTypeGroup: React.FC<ShelfTypeGroupProps> = ({
  shelfTypeKey,
  metrics,
  maxValues,
  isExpanded,
  onExpand,
  startDate,
  endDate,
}) => {
  const summaryMetrics = calculateSummaryMetrics(metrics, startDate, endDate);

  return (
    <React.Fragment>
      <TableRow
        onClick={() => onExpand(shelfTypeKey)}
        sx={{
          cursor: 'pointer',
          '&:hover': { bgcolor: 'action.hover' },
        }}
      >
        <TableCell
          sx={{
            position: 'sticky',
            left: 0,
            backgroundColor: 'white',
            zIndex: 2,
            borderRight: '1px solid',
            borderRightColor: 'divider',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <IconButton size="small">
              {isExpanded ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronRight size={20} />
              )}
            </IconButton>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {metrics[0].shelf_type} ({metrics.length}種類)
            </Typography>
          </Box>
        </TableCell>
        {columns.map((column) => {
          const value =
            column.id === 'revenue'
              ? calculateMonthlyRevenue(
                  safeNumber(summaryMetrics.cv),
                  startDate,
                  endDate,
                )
              : safeNumber(summaryMetrics[column.id]);
          return (
            <TableCell
              key={column.id}
              align="right"
              sx={{ position: 'relative' }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: `${
                    maxValues[column.id] > 0
                      ? (value / maxValues[column.id]) * 100
                      : 0
                  }%`,
                  bgcolor: 'primary.main',
                  opacity: 0.1,
                }}
              />
              <Typography sx={{ position: 'relative' }}>
                {column.format(value)}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={columns.length + 1} sx={{ p: 0 }}>
            <Box
              sx={{
                maxHeight: '400px',
                overflowY: 'auto',
                bgcolor: 'grey.50',
              }}
            >
              {metrics.map((metric) => (
                <MetricsRow
                  key={`${metric.shelf_type}-${metric.gray_tagline}-${metric.bold_tagline}`}
                  metric={metric}
                  maxValues={maxValues}
                  startDate={startDate}
                  endDate={endDate}
                />
              ))}
            </Box>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
