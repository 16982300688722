import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';
import { IItem } from '../../insightx-utils/types/item.type';
import { IShelf } from '../../insightx-utils/types/shelf.type';

interface HeatmapProps {
  shelf: IShelf;
  groupBy: 'category' | 'brand';
}

interface GroupCount {
  group: string;
  [date: string]: number | string;
  total: number;
}

const ShelfItemHeatmap: React.FC<HeatmapProps> = ({ shelf, groupBy }) => {
  const theme = useTheme();

  const groupCounts = useMemo(() => {
    const counts: { [key: string]: GroupCount } = {};
    const dates = new Set<string>();

    shelf.items.forEach((item: IItem) => {
      const date = new Date(item.createdAt).toLocaleDateString('ja-JP', {
        month: '2-digit',
        day: '2-digit',
      });
      dates.add(date);

      const groupKey = item[groupBy];
      if (!counts[groupKey]) {
        counts[groupKey] = { group: groupKey, total: 0 };
      }

      counts[groupKey][date] = ((counts[groupKey][date] as number) || 0) + 1;
      counts[groupKey].total = (counts[groupKey].total || 0) + 1;
    });

    const sortedCounts = Object.values(counts).sort(
      (a, b) => b.total - a.total,
    );
    return { counts: sortedCounts, dates: Array.from(dates).sort() };
  }, [shelf.items, groupBy]);

  const maxCount = useMemo(() => {
    return Math.max(
      ...Object.values(groupCounts.counts).flatMap(
        (count) =>
          Object.values(count).filter(
            (value) => typeof value === 'number' && value !== count.total,
          ) as number[],
      ),
    );
  }, [groupCounts]);

  const getColor = (count: number) => {
    if (count === 0) return 'white';
    const intensity = count / maxCount;
    return `rgba(220, 0, 78, ${intensity * 0.7 + 0.3})`;
  };

  const totalsByDate = useMemo(() => {
    return groupCounts.dates.reduce(
      (totals, date) => {
        totals[date] = groupCounts.counts.reduce(
          (sum, group) => sum + ((group[date] as number) || 0),
          0,
        );
        return totals;
      },
      {} as { [date: string]: number },
    );
  }, [groupCounts]);

  const grandTotal = groupCounts.counts.reduce(
    (sum, group) => sum + group.total,
    0,
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxWidth: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        height: '125px',
      }}
    >
      <Table
        stickyHeader
        size="small"
        sx={{
          '& th, & td': { padding: '4px 8px', fontSize: '0.75rem' },
          tableLayout: 'fixed',
          height: '100%',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: 'rgb(0, 0, 0)',
                backgroundColor: 'rgb(225, 225, 225)',
                position: 'sticky',
                left: 0,
                top: 0,
                zIndex: 3,
                width: '120px',
                fontSize: '0.75rem',
              }}
            >
              {groupBy === 'category' ? 'カテゴリ' : 'ブランド'}
            </TableCell>
            {groupCounts.dates.map((date) => (
              <TableCell
                key={date}
                align="center"
                sx={{
                  fontWeight: 'bold',
                  color: 'rgb(0, 0, 0)',
                  backgroundColor: 'rgb(225, 225, 225)',
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  width: '80px',
                  fontSize: '0.75rem',
                }}
              >
                {date}
              </TableCell>
            ))}
            <TableCell
              align="center"
              sx={{
                fontWeight: 'bold',
                color: 'rgb(0, 0, 0)',
                backgroundColor: 'rgb(225, 225, 225)',
                position: 'sticky',
                top: 0,
                right: 0,
                zIndex: 3,
                width: '80px',
                fontSize: '0.75rem',
              }}
            >
              合計
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ height: 'calc(100% - 60px)', overflow: 'auto' }}>
          {groupCounts.counts.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                '&:nth-of-type(even)': {
                  backgroundColor: theme.palette.action.hover,
                },
                height: '30px',
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontWeight: 'bold',
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  zIndex: 1,
                }}
              >
                {row.group}
              </TableCell>
              {groupCounts.dates.map((date) => (
                <TableCell
                  key={date}
                  align="center"
                  sx={{
                    bgcolor: getColor((row[date] as number) || 0),
                    color: (row[date] as number) > 0 ? 'white' : 'inherit',
                    fontWeight: 'bold',
                  }}
                >
                  {row[date] || ''}
                </TableCell>
              ))}
              <TableCell
                align="center"
                sx={{
                  bgcolor: getColor(row.total),
                  color: 'white',
                  fontWeight: 'bold',
                  position: 'sticky',
                  right: 0,
                  zIndex: 2,
                }}
              >
                {row.total}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table
        size="small"
        sx={{
          tableLayout: 'fixed',
          position: 'sticky',
          bottom: 0,
          zIndex: 2,
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0px -2px 4px rgba(0,0,0,0.1)',
          '& th, & td': { fontSize: '0.75rem' },
        }}
      >
        <TableBody>
          <TableRow
            sx={{ backgroundColor: theme.palette.grey[200], height: '30px' }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{
                fontWeight: 'bold',
                position: 'sticky',
                left: 0,
                backgroundColor: theme.palette.grey[200],
                zIndex: 3,
                color: 'rgb(0, 0, 0)',
                width: '120px',
              }}
            >
              合計
            </TableCell>
            {groupCounts.dates.map((date) => (
              <TableCell
                key={date}
                align="center"
                sx={{
                  bgcolor: getColor(totalsByDate[date]),
                  color: 'white',
                  fontWeight: 'bold',
                  width: '80px',
                }}
              >
                {totalsByDate[date]}
              </TableCell>
            ))}
            <TableCell
              align="center"
              sx={{
                bgcolor: getColor(grandTotal),
                color: 'white',
                fontWeight: 'bold',
                position: 'sticky',
                right: 0,
                zIndex: 4,
                width: '80px',
              }}
            >
              {grandTotal}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShelfItemHeatmap;
