import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const AccordionContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
    box-shadow: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    background-color: #f5f5f5;
  }
`;

const InfoAccordions: React.FC = () => {
  return (
    <AccordionContainer>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>新商品から作成</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Typography>
            詳細は
            <Link
              href="https://www.notion.so/insightx-tech/0f072e0285874f85958f71248f719caa"
              target="_blank"
              rel="noopener noreferrer"
            >
              Notion
            </Link>
            を参照してください。
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>未カバー商品から作成</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Typography>
            詳細は
            <Link
              href="https://www.notion.so/insightx-tech/9be9536542ac469f9e6cb482e2accf08?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Notion
            </Link>
            を参照してください。
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
    </AccordionContainer>
  );
};

export default InfoAccordions;
