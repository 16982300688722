import type { IDomService } from './dom.service.interface';

export class DomService implements IDomService {
  insertDoms(): Promise<void> {
    return;
  }

  initShelfAreaShown(): Promise<void> {
    return;
  }
}
