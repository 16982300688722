import { ShelfUiTypeEnum } from '../../enums/shelf-ui-type.enum';
import { IShelf } from '../../types/shelf.type';
import { setShelfStyle } from './set-shelf-style';
import { ShelfStyleKind } from './shelf-style-kind.type';

const initializeEmptyObject = <T>(obj: T | undefined, keys: (keyof T)[]): T => {
  const result = obj || ({} as T);
  keys.forEach((key) => {
    if (!result[key]) {
      result[key] = {} as T[keyof T];
    }
  });
  return result;
};

export const preprocessShelfOptions = (shelf: IShelf): IShelf => {
  shelf.shelfOption = initializeEmptyObject(shelf.shelfOption, ['shelfStyle']);
  shelf.taglineOption = initializeEmptyObject(shelf.taglineOption, [
    'taglinesStyle',
    'tagline1Style',
    'tagline2Style',
  ]);
  shelf.itemOption = initializeEmptyObject(shelf.itemOption, [
    'itemsStyle',
    'itemScrollStyle',
    'itemStyle',
    'imageStyle',
    'infoStyle',
    'brandStyle',
    'titleStyle',
    'priceStyle',
    'salePriceStyle',
    'iconsStyle',
    'iconStyle',
  ]);

  // fontFamilyの設定
  if (shelf.shelfOption.fontFamily) {
    Object.values(ShelfStyleKind).forEach((styleKind) => {
      shelf = setShelfStyle(
        shelf,
        styleKind,
        'fontFamily',
        shelf.shelfOption.fontFamily!,
      );
    });
  }

  // 文字行数(lineClamp)系の設定
  ['title', 'brand'].forEach((key) => {
    const lineClamp = shelf.itemOption[`${key}LineClamp`] || 2;
    shelf = setShelfStyle(
      shelf,
      key as ShelfStyleKind,
      'display',
      '-webkit-box',
    );
    shelf = setShelfStyle(
      shelf,
      key as ShelfStyleKind,
      '-webkit-line-clamp',
      `${lineClamp}`,
    );
    shelf = setShelfStyle(shelf, key as ShelfStyleKind, 'max-width', '100%');
    shelf = setShelfStyle(
      shelf,
      key as ShelfStyleKind,
      '-webkit-box-orient',
      'vertical',
    );
    shelf = setShelfStyle(shelf, key as ShelfStyleKind, 'overflow', 'hidden');
  });

  // uiTypeごとのスタイル設定
  if (shelf.uiType === ShelfUiTypeEnum.slider) {
    shelf = setSliderStyles(shelf);
  } else if (shelf.uiType === ShelfUiTypeEnum.grid) {
    shelf = setGridStyles(shelf);
  }

  return shelf;
};

const setSliderStyles = (shelf: IShelf): IShelf => {
  const sliderStyles = [
    {
      kind: ShelfStyleKind.Items,
      styles: { width: '100%', 'overflow-x': 'auto' },
    },
    {
      kind: ShelfStyleKind.ItemScroll,
      styles: {
        display: 'inline-grid',
        'grid-auto-flow': 'column',
        'grid-template-rows': `repeat(${shelf.shelfOption?.sliderRows || 1}, 1fr)`,
        gap: `${shelf.shelfOption?.columnGap || 0}px`,
      },
    },
  ];

  const scrollbarStyles = {
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    '-webkit-overflow-scrolling': 'touch',
  };

  sliderStyles.forEach(({ kind, styles }) => {
    Object.entries(styles).forEach(([key, value]) => {
      shelf = setShelfStyle(shelf, kind, key, value);
    });
  });

  [ShelfStyleKind.Items, ShelfStyleKind.ItemScroll].forEach((kind) => {
    Object.entries(scrollbarStyles).forEach(([key, value]) => {
      shelf = setShelfStyle(shelf, kind, key, value);
    });
  });

  return shelf;
};

const setGridStyles = (shelf: IShelf): IShelf => {
  const gridStyles = {
    display: 'grid',
    'grid-template-columns': `repeat(${shelf.shelfOption?.gridColumns || 2}, 1fr)`,
    gap: `${shelf.shelfOption?.columnGap || 0}px`,
  };

  Object.entries(gridStyles).forEach(([key, value]) => {
    shelf = setShelfStyle(shelf, ShelfStyleKind.ItemScroll, key, value);
  });

  return shelf;
};
