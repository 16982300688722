import React from 'react';
import ListPageCard from './ListPageCard';
import styled from '@emotion/styled';
import { IShelfConfig } from '../../types/shelf-config.type';

interface ListPageGridProps {
  urlsForCards: string[][];
  selectedUrls: (string | null)[];
  onUrlSelect: (cardIndex: number, url: string) => void;
  shelfConfig: IShelfConfig;
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ListPageGrid: React.FC<ListPageGridProps> = ({
  urlsForCards,
  selectedUrls,
  onUrlSelect,
  shelfConfig,
}) => {
  return (
    <GridContainer>
      {urlsForCards.map((urls, index) => (
        <ListPageCard
          key={index}
          cardIndex={index}
          urls={urls}
          selectedUrl={selectedUrls[index]}
          onUrlSelect={onUrlSelect}
          shelfConfig={shelfConfig}
        />
      ))}
    </GridContainer>
  );
};

export default ListPageGrid;
