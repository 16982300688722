/* eslint-disable @typescript-eslint/no-unused-vars */
import { ShelfUiTypeEnum } from '../../../enums/shelf-ui-type.enum';
import type { IItemOption } from '../../../types/item-option.type';
import type { IShelfOption } from '../../../types/shelf-option.type';
import type { IShelf } from '../../../types/shelf.type';
import type { ITaglineOption } from '../../../types/tagline-option.type';
import { PreprocessServiceBase } from '../preprocess.base.service';

export class OnwardPreprocessService extends PreprocessServiceBase {
  private fontFamily =
    "Inter, arial, '游ゴシック Medium', Yu Gothic Medium, '永ゴシック体', YuGothic, 'ヒラギノ角ゴ Pro', Hiragino Kaku Gothic Pro, 'メイリオ', meiryo, 'MS Pゴシック', MS PGothic, sans-serif";

  async getShelfOverride(shelf: IShelf): Promise<IShelf> {
    const uiType: ShelfUiTypeEnum = ShelfUiTypeEnum.slider;
    return { uiType };
  }

  async getOptions(shelf: IShelf): Promise<{
    shelfOption: IShelfOption;
    taglineOption: ITaglineOption;
    itemOption: IItemOption;
  }> {
    shelf.shelfOption.fontFamily = this.fontFamily;

    const {
      shelfStyle,
      tagline1Style,
      tagline2Style,
      itemsStyle,
      itemScrollStyle,
      titleStyle,
      brandStyle,
      priceStyle,
    } = await super.getBaseStyles(shelf);

    const shelfOption: IShelfOption = {
      columnGap: 1,
      shelfStyle: {
        ...shelfStyle,
        marginBottom: '10px',
        padding: '3px',
      },
    };

    const taglineOption: ITaglineOption = {
      taglinesStyle: {
        marginBottom: '10px',
        marginLeft: '13px',
      },
      tagline1Style: {
        ...tagline1Style,
        fontSize: '13px',
        color: '#828282',
        fontWeight: 'bold',
      },
      tagline2Style: {
        ...tagline2Style,
        fontSize: '16px',
        color: '#000',
        fontWeight: 'bold',
      },
    };

    const itemOption: IItemOption = {
      widthVw: 30,
      imageRatio: 380.0 / 317.0,
      maxWidth: 184,
      titleLineClamp: 1,
      brandLineClamp: 1,
      itemsStyle: {
        ...itemsStyle,
      },
      itemScrollStyle: {
        ...itemScrollStyle,
        marginLeft: '13px',
        marginRight: '13px',
      },
      itemStyle: {
        background: '#fff',
      },
      imageStyle: {
        borderRadius: '10px',
        overflow: 'hidden',
      },
      infoStyle: {
        padding: '6px',
      },
      brandStyle: {
        ...brandStyle,
        fontSize: '11px',
        color: '#8f929b',
      },
      titleStyle: {
        ...titleStyle,
        fontSize: '11px',
        color: '#5a5a5a',
      },
      priceStyle: {
        ...priceStyle,
        fontSize: '11px',
      },
    };

    return { shelfOption, taglineOption, itemOption };
  }
}
