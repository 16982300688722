import {
  IPostRecommendsReq,
  IPostRecommendsRes,
} from '../../types/post-recommends.type';
import api from '../api';
import { preprocessShelfOptions } from '../shelf/preprocess-shelf-options';

export const postRecommends = async (
  dto: IPostRecommendsReq,
): Promise<IPostRecommendsRes> => {
  const res = (await api.post(`/recommends/recommends`, dto)).data;
  if (res.shelves) {
    res.shelves = res.shelves.map((shelf) => preprocessShelfOptions(shelf));
  }
  return res;
};
