import { Box, Paper, useTheme } from '@mui/material';
import { endOfDay, format, startOfDay, subDays, subMonths } from 'date-fns';
import { Calendar } from 'lucide-react';
import { DateRangePicker } from 'rsuite';
import type { DateRange, RangeType } from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';

interface CustomDateRangePickerProps {
  startDate: string;
  endDate: string;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
}

const ranges: RangeType<DateRange>[] = [
  {
    label: '今日',
    value: () => [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: '過去7日間',
    value: () => [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
  },
  {
    label: '過去30日間',
    value: () => [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
  },
  {
    label: '過去3ヶ月',
    value: () => [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  },
  {
    label: '過去6ヶ月',
    value: () => [startOfDay(subMonths(new Date(), 6)), endOfDay(new Date())],
  },
];

export const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const theme = useTheme();

  const handleChange = (dates: [Date, Date] | null) => {
    if (dates) {
      onStartDateChange(format(dates[0], 'yyyy-MM-dd'));
      onEndDateChange(format(dates[1], 'yyyy-MM-dd'));
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Paper
        elevation={0}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          p: 1,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
          '&:hover': {
            borderColor: theme.palette.primary.main,
          },
        }}
      >
        <Calendar
          size={20}
          style={{
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
          }}
        />
        <DateRangePicker
          ranges={ranges}
          value={[new Date(startDate), new Date(endDate)]}
          onChange={handleChange}
          placeholder="期間を選択"
          format="yyyy-MM-dd"
          character="～"
          locale={{
            sunday: '日',
            monday: '月',
            tuesday: '火',
            wednesday: '水',
            thursday: '木',
            friday: '金',
            saturday: '土',
            ok: '確定',
            today: '今日',
            yesterday: '昨日',
            last7Days: '過去7日間',
            hours: '時',
            minutes: '分',
            seconds: '秒',
          }}
          style={{
            width: 300,
          }}
          menuStyle={{
            zIndex: 1400,
          }}
          cleanable={false}
          toggleAs="div"
          size="lg"
          block
        />
      </Paper>
    </Box>
  );
};

export default CustomDateRangePicker;
