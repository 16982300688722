import React, { useEffect, useState } from 'react';
import { useClient } from '../../store/client.store';
import { getListPages } from '../../services/shelf-previewer/get-list-pages';
import { IShelfConfig } from '../../types/shelf-config.type';
import ListPageGrid from './ListPageGrid';
import { IListPage } from '../../types/list-page.type';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';
import { RecommendLogicEnum } from '../../enums/recommend-logic.enum';
import ShelfConfigBuilder from '../shelf-config-builder/ShelfConfigBuilder';

const ListShelfPreviewer: React.FC = () => {
  const [listPages, setListPages] = useState<IListPage[]>([]);
  const [shelfConfig, setShelfConfig] = useState<IShelfConfig>({
    historyFilteredItemNum: 100,
    shelfOrders: [
      {
        shelfType: ShelfTypeEnum.related,
        shelfNum: 1,
        recommendLogic: RecommendLogicEnum.Covis,
        maxItems: 50,
      },
    ],
  });
  const [selectedUrls, setSelectedUrls] = useState<(string | null)[]>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const { client: clientState } = useClient();

  // クライアントIDが変更されたときにリストページを取得
  useEffect(() => {
    const fetchListPages = async () => {
      try {
        const res = await getListPages();
        setListPages(res);

        // URLをカードごとにグループ化
        const urlsForCards: string[][] = [[], [], [], [], [], []];
        res.forEach((listPage, index) => {
          const cardIndex = index % 6;
          urlsForCards[cardIndex].push(listPage.url);
        });
        // 各カードのデフォルト選択URLを設定
        const initialSelectedUrls = urlsForCards.map((urls) => urls[0] || null);
        setSelectedUrls(initialSelectedUrls);
      } catch (error) {
        console.error('リストページの取得に失敗しました:', error);
      }
    };
    fetchListPages();
  }, [clientState.selectedClient.clientId]);

  // URLをカードごとにグループ化
  const urlsForCards: string[][] = [[], [], [], [], [], []];
  listPages.forEach((listPage, index) => {
    const cardIndex = index % 6;
    urlsForCards[cardIndex].push(listPage.url);
  });

  // カード内のURL選択ハンドラー
  const handleUrlSelection = (cardIndex: number, url: string) => {
    const newSelectedUrls = [...selectedUrls];
    newSelectedUrls[cardIndex] = url;
    setSelectedUrls(newSelectedUrls);
  };

  return (
    <div>
      {/* ShelfConfigBuilder */}
      <ShelfConfigBuilder
        shelfConfig={shelfConfig}
        onConfigChange={setShelfConfig}
        baseType="item-based"
      />
      {/* ListPageGrid */}
      <ListPageGrid
        urlsForCards={urlsForCards}
        selectedUrls={selectedUrls}
        onUrlSelect={handleUrlSelection}
        shelfConfig={shelfConfig}
      />
    </div>
  );
};

export default ListShelfPreviewer;
