// services/shelf-builder/get-shelf-by-id.ts

import { IStgSearchShelfQuery } from '../../types/stg-search-shelf-query.type';
import api from '../api';

export const getShelfById = async (
  shelf_id: string,
): Promise<IStgSearchShelfQuery> => {
  const res = await api.get('/shelf/stg_search_shelf_queries', {
    params: { shelf_id },
  });
  return res.data;
};
