// services/shelf-builder/get-stg-search-shelf-queries.ts

import { IStgSearchShelfQuery } from '../../types/stg-search-shelf-query.type';
import api from '../api';

export const getStgSearchShelfQueries = async (): Promise<
  IStgSearchShelfQuery[]
> => {
  const res = await api.get('/shelf/stg_search_shelf_queries');
  return res.data;
};
