import { Box, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { columns } from './constants';
import { MetricsRowProps } from './types';
import { calculateMonthlyRevenue, safeNumber } from './utils';

export const MetricsRow: React.FC<MetricsRowProps> = React.memo(
  ({ metric, maxValues, startDate, endDate }) => {
    return (
      <TableRow
        sx={{
          '&:hover': { bgcolor: 'action.hover' },
          backgroundColor: 'grey.50',
        }}
      >
        <TableCell
          sx={{
            pl: 6,
            position: 'sticky',
            left: 0,
            backgroundColor: 'grey.50',
            borderRight: '1px solid',
            borderRightColor: 'divider',
            zIndex: 2,
          }}
        >
          <Typography variant="body2">
            {metric.gray_tagline} {metric.bold_tagline}
          </Typography>
        </TableCell>
        {columns.map((column) => {
          const value =
            column.id === 'revenue'
              ? calculateMonthlyRevenue(
                  safeNumber(metric.cv),
                  startDate,
                  endDate,
                )
              : safeNumber(metric[column.id]);
          return (
            <TableCell
              key={column.id}
              align="right"
              sx={{ position: 'relative', height: '48px' }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: `${
                    maxValues[column.id] > 0
                      ? (value / maxValues[column.id]) * 100
                      : 0
                  }%`,
                  bgcolor: 'primary.main',
                  opacity: 0.1,
                }}
              />
              <Typography sx={{ position: 'relative' }}>
                {column.format(value)}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  },
);

MetricsRow.displayName = 'MetricsRow';
