import React from 'react';
import styled from 'styled-components';
import { IItem } from '../../insightx-utils/types/item.type';
import { IShelf } from '../../insightx-utils/types/shelf.type';

interface BannerProps {
  shelf: IShelf;
  items: IItem[];
  tagline1?: string;
  tagline2?: string;
}

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(
      180deg,
      transparent 0%,
      rgba(0, 0, 0, 0.1) 20%,
      rgba(0, 0, 0, 0.7) 100%
    );
    pointer-events: none;
  }
`;

const ImageContainer = styled.a`
  text-decoration: none;
  display: flex;
  width: 100%;
  height: 100%;
`;

const Image = styled.div<{ url: string }>`
  flex: 1;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
`;

const TaglineContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Tagline1 = styled.h2`
  font-size: 15px;
  margin: 0;
`;

const Tagline2 = styled.h3`
  font-size: 18px;
  margin: 5px 0 0;
`;

const Banner: React.FC<BannerProps> = ({
  shelf,
  items,
  tagline1,
  tagline2,
}) => {
  return (
    <BannerContainer>
      <ImageContainer href={shelf.showMoreLink || '#'} target="_blank">
        {items.slice(0, 2).map((item, index) => (
          <Image key={index} url={item.imageUrl || ''} />
        ))}
      </ImageContainer>
      <TaglineContainer>
        {tagline1 && <Tagline1>{tagline1}</Tagline1>}
        {tagline2 && <Tagline2>{tagline2}</Tagline2>}
      </TaglineContainer>
    </BannerContainer>
  );
};

export default Banner;
