import React from 'react';
import {
  TaglinesContainer,
  Tagline1,
  Tagline2,
} from '../shelf-previewer/StyledComponents';

interface ShelfTaglinesProps {
  tagline1: string;
  tagline2: string;
}

const ShelfTaglines: React.FC<ShelfTaglinesProps> = ({
  tagline1,
  tagline2,
}) => {
  return (
    <TaglinesContainer>
      <Tagline1>{tagline1}</Tagline1>
      <Tagline2>{tagline2}</Tagline2>
    </TaglinesContainer>
  );
};

export default ShelfTaglines;
