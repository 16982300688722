/* eslint-disable @typescript-eslint/no-unused-vars */
import { ShelfUiTypeEnum } from '../../enums/shelf-ui-type.enum';
import type { IItemOption } from '../../types/item-option.type';
import type { IShelfGroup } from '../../types/shelf-group.type';
import type { IShelfOption } from '../../types/shelf-option.type';
import type { IShelf } from '../../types/shelf.type';
import { IStyle } from '../../types/style.type';
import type { ITaglineOption } from '../../types/tagline-option.type';
import type { Config } from '../config/config';
import type { IPreprocessService } from './preprocess.service.interface';

export class PreprocessServiceBase implements IPreprocessService {
  config: Config;
  constructor(config: Config) {
    this.config = config;
  }

  async preprocessShelves(shelves: IShelf[]): Promise<IShelf[]> {
    const newShelves: IShelf[] = [];
    for (const shelf of shelves) {
      newShelves.push({
        ...shelf,
        ...(await this.getShelfOverride(shelf)),
        ...(await this.getOptions(shelf)),
      });
    }
    return newShelves;
  }

  async groupShelves(shelves: IShelf[]): Promise<Record<string, IShelfGroup>> {
    const shelfGroups: Record<string, IShelfGroup> = {};

    shelves.forEach((shelf) => {
      const shelfGroup = shelf.shelfGroup || 'おすすめ';
      if (!shelfGroups[shelfGroup]) {
        shelfGroups[shelfGroup] = {
          shelves: [],
          targetSelector: '',
        };
      }
      shelfGroups[shelfGroup].shelves.push(shelf);
    });

    return shelfGroups;
  }

  async getShelfOverride(shelf: IShelf): Promise<IShelf> {
    return {};
  }

  async getOptions(shelf: IShelf): Promise<{
    shelfOption: IShelfOption;
    taglineOption: ITaglineOption;
    itemOption: IItemOption;
  }> {
    const {
      shelfStyle,
      tagline1Style,
      tagline2Style,
      itemsStyle,
      itemScrollStyle,
      titleStyle,
      brandStyle,
      priceStyle,
    } = await this.getBaseStyles(shelf);
    return {
      shelfOption: {
        shelfStyle,
      },
      taglineOption: {
        tagline1Style,
        tagline2Style,
      },
      itemOption: {
        itemsStyle,
        itemScrollStyle,
        titleStyle,
        brandStyle,
        priceStyle,
      },
    };
  }

  async getBaseStyles(shelf: IShelf): Promise<{
    shelfStyle: IStyle;
    tagline1Style: IStyle;
    tagline2Style: IStyle;
    itemsStyle: IStyle;
    itemScrollStyle: IStyle;
    titleStyle: IStyle;
    brandStyle: IStyle;
    priceStyle: IStyle;
  }> {
    const shelfStyle: IStyle = {};
    const tagline1Style: IStyle = {};
    const tagline2Style: IStyle = {};
    const itemsStyle: IStyle = {};
    const itemScrollStyle: IStyle = {};
    const titleStyle: IStyle = {};
    const brandStyle: IStyle = {};
    const priceStyle: IStyle = {};

    // fontFamilyを設定
    if (shelf.shelfOption.fontFamily) {
      tagline1Style.fontFamily = shelf.shelfOption.fontFamily;
      tagline2Style.fontFamily = shelf.shelfOption.fontFamily;
      titleStyle.fontFamily = shelf.shelfOption.fontFamily;
      brandStyle.fontFamily = shelf.shelfOption.fontFamily;
      priceStyle.fontFamily = shelf.shelfOption.fontFamily;
    }

    // スクロールバーを非表示にする
    if (!shelf.notDisableScrollBar) {
      shelfStyle.scrollbarWidth = 'none';
      shelfStyle['-ms-overflow-style'] = 'none';
      shelfStyle['-webkit-overflow-scrolling'] = 'touch';

      itemsStyle.scrollbarWidth = 'none';
      itemsStyle['-ms-overflow-style'] = 'none';
      itemsStyle['-webkit-overflow-scrolling'] = 'touch';

      itemScrollStyle.scrollbarWidth = 'none';
      itemScrollStyle['-ms-overflow-style'] = 'none';
      itemScrollStyle['-webkit-overflow-scrolling'] = 'touch';
    }

    // 文字行数(lineClamp)系の設定
    const titleLineClamp = shelf.itemOption.titleLineClamp || 2;
    titleStyle.display = '-webkit-box';
    titleStyle['-webkit-line-clamp'] = `${titleLineClamp}`;
    titleStyle['-webkit-box-orient'] = 'vertical';
    titleStyle.overflow = 'hidden';

    const brandLineClamp = shelf.itemOption.brandLineClamp || 1;
    brandStyle.display = '-webkit-box';
    brandStyle['-webkit-line-clamp'] = `${brandLineClamp}`;
    brandStyle['-webkit-box-orient'] = 'vertical';
    brandStyle.overflow = 'hidden';

    // sliderの設定
    if (shelf.uiType === ShelfUiTypeEnum.slider) {
      itemsStyle.width = '100%';
      itemsStyle.overflowX = 'auto';

      itemScrollStyle.display = 'inline-grid';
      itemScrollStyle.gridAutoFlow = 'column';
      itemScrollStyle.gridTemplateRows = `repeat(${shelf.shelfOption?.sliderRows || 1}, 1fr)`;
      itemScrollStyle.gap = `${shelf.shelfOption?.columnGap || 0}px`;
    }

    // gridの設定
    if (shelf.uiType === ShelfUiTypeEnum.grid) {
      itemScrollStyle.display = 'grid';
      itemScrollStyle.gridTemplateColumns = `repeat(${shelf.shelfOption?.gridColumns || 2}, 1fr)`;
      itemScrollStyle.gap = `${shelf.shelfOption?.columnGap || 0}px`;
    }

    return {
      shelfStyle,
      tagline1Style,
      tagline2Style,
      itemsStyle,
      itemScrollStyle,
      titleStyle,
      brandStyle,
      priceStyle,
    };
  }
}
