import { IShelfStyleKind } from '../types/shelf-style-kind.type';
import { IShelf } from '../types/shelf.type';
import { IStyle } from '../types/style.type';
import { StyleUtil } from './style-util';

const styleMap: Record<IShelfStyleKind, (shelf: IShelf) => IStyle | undefined> =
  {
    [IShelfStyleKind.Shelf]: (shelf) => shelf.shelfOption?.shelfStyle,
    [IShelfStyleKind.Taglines]: (shelf) => shelf.taglineOption?.taglinesStyle,
    [IShelfStyleKind.Tagline1]: (shelf) => shelf.taglineOption?.tagline1Style,
    [IShelfStyleKind.Tagline2]: (shelf) => shelf.taglineOption?.tagline2Style,
    [IShelfStyleKind.Items]: (shelf) => shelf.itemOption?.itemsStyle,
    [IShelfStyleKind.ItemScroll]: (shelf) => shelf.itemOption?.itemScrollStyle,
    [IShelfStyleKind.Item]: (shelf) => shelf.itemOption?.itemStyle,
    [IShelfStyleKind.Image]: (shelf) => shelf.itemOption?.imageStyle,
    [IShelfStyleKind.Info]: (shelf) => shelf.itemOption?.infoStyle,
    [IShelfStyleKind.Brand]: (shelf) => shelf.itemOption?.brandStyle,
    [IShelfStyleKind.Title]: (shelf) => shelf.itemOption?.titleStyle,
    [IShelfStyleKind.Price]: (shelf) => shelf.itemOption?.priceStyle,
    [IShelfStyleKind.SalePrice]: (shelf) => shelf.itemOption?.salePriceStyle,
    [IShelfStyleKind.Icons]: (shelf) => shelf.itemOption?.iconsStyle,
    [IShelfStyleKind.Icon]: (shelf) => shelf.itemOption?.iconStyle,
  };

export const ShelfStyleUtil = {
  getShelfStyle: (shelf: IShelf, styleKind: IShelfStyleKind): string => {
    const styleObject = styleMap[styleKind](shelf);
    return StyleUtil.styleToCSS(styleObject ?? ({} as IStyle));
  },
};
