import React from 'react';
import styled from 'styled-components';
import { ShelfUiTypeEnum } from '../../enums/shelf-ui-type.enum';
import { IShelf } from '../../insightx-utils/types/shelf.type';
import { ShelfStyleUtil } from '../../insightx-utils/utils/shelf-style-util';
import GridShelf from './GridShelf';
import SliderShelf from './SliderShelf';

interface ShelfProps {
  shelf: IShelf;
}

const ShelfContainer = styled.div<{
  customStyle?: string;
}>`
  ${(props) => props.customStyle || ''}
`;

const Shelf: React.FC<ShelfProps> = ({ shelf }) => {
  if (!shelf.items || shelf.items.length === 0) {
    return null;
  }

  return (
    <ShelfContainer customStyle={ShelfStyleUtil.getShelfStyle(shelf, 'shelf')}>
      {shelf.uiType === ShelfUiTypeEnum.slider && <SliderShelf shelf={shelf} />}
      {shelf.uiType === ShelfUiTypeEnum.grid && <GridShelf shelf={shelf} />}
    </ShelfContainer>
  );
};

export default Shelf;
