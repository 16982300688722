import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';
import { IUser } from '../types/user.type';

const userAtom = atomWithStorage<IUser | null>('ix_dashboard_user', null);
export const isAuthenticatedAtom = atom((get) => get(userAtom) !== null);
export const isLoadingAtom = atom(false); // Changed initial value to false

export const authAtom = atom(
  (get) => ({
    user: get(userAtom),
    isAuthenticated: get(isAuthenticatedAtom),
    isLoading: get(isLoadingAtom),
  }),
  async (
    get,
    set,
    action:
      | { type: 'login'; payload: { user: IUser; token: string } }
      | { type: 'logout' }
      | {
          type: 'loginWithCredentials';
          payload: { user: IUser; token: string };
        },
  ) => {
    switch (action.type) {
      case 'login':
      case 'loginWithCredentials':
        const { user, token } = action.payload;
        // Store both user and token
        localStorage.setItem('ix_dashboard_token', token);
        set(userAtom, { ...user, token });
        set(isLoadingAtom, false);
        break;
      case 'logout':
        localStorage.removeItem('ix_dashboard_token');
        set(userAtom, null);
        set(isLoadingAtom, false);
        break;
    }
  },
);

export const useAuth = () => {
  const [auth, dispatch] = useAtom(authAtom);

  const login = useCallback(
    (user: IUser, token: string) => {
      dispatch({ type: 'login', payload: { user, token } });
    },
    [dispatch],
  );

  const loginWithCredentials = useCallback(
    (user: IUser, token: string) => {
      dispatch({ type: 'loginWithCredentials', payload: { user, token } });
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    dispatch({ type: 'logout' });
  }, [dispatch]);

  return { auth, login, loginWithCredentials, logout };
};
