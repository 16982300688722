import { ItemKindEnum } from '../../../enums/item-kind.enum';
import { ShelfTypeEnum } from '../../../enums/shelf-type.enum';
import { ShelfUiTypeEnum } from '../../../enums/shelf-ui-type.enum';
import type { IItemOption } from '../../../types/item-option.type';
import type { IShelfOption } from '../../../types/shelf-option.type';
import type { IShelf } from '../../../types/shelf.type';
import type { ITaglineOption } from '../../../types/tagline-option.type';
import { PreprocessServiceBase } from '../preprocess.base.service';

export class PalPreprocessService extends PreprocessServiceBase {
  private fontFamily =
    '\'Lato\', "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", \'Hiragino Kaku Gothic ProN\', "メイリオ", Meiryo, "Lucida Grande", Verdana, "ＭＳ Ｐゴシック", sans-serif';

  async getShelfOverride(shelf: IShelf): Promise<IShelf> {
    let uiType: ShelfUiTypeEnum = ShelfUiTypeEnum.slider;
    if (shelf.itemKind === ItemKindEnum.Article) {
      uiType = ShelfUiTypeEnum.grid;
    }

    return { uiType };
  }

  async getOptions(shelf: IShelf): Promise<{
    shelfOption: IShelfOption;
    taglineOption: ITaglineOption;
    itemOption: IItemOption;
  }> {
    shelf.shelfOption.fontFamily = this.fontFamily;
    const {
      shelfStyle,
      tagline1Style,
      tagline2Style,
      itemsStyle,
      itemScrollStyle,
      titleStyle,
      brandStyle,
      priceStyle,
    } = await super.getBaseStyles(shelf);
    const shelfOption: IShelfOption = {
      shelfStyle: {
        ...shelfStyle,
        marginBottom: '40px',
        padding: '20px 0',
      },
    };
    const taglineOption: ITaglineOption = {
      taglinesStyle: {
        textAlign: 'center',
        marginBottom: '20px',
      },
      tagline1Style: {
        ...tagline1Style,
        fontSize: this.config.getIsMobile() ? '12px' : '22px',
      },
      tagline2Style: {
        ...tagline2Style,
        fontSize: this.config.getIsMobile() ? '18px' : '18px',
      },
    };
    const itemOption: IItemOption = {
      widthVw: 42,
      imageRatio: 230.0 / 192.0,
      maxWidth: 184,
      titleLineClamp: 2,
      brandLineClamp: 1,
      itemsStyle: {
        ...itemsStyle,
      },
      itemScrollStyle: {
        ...itemScrollStyle,
        marginLeft: '13px',
        marginRight: '13px',
      },
      brandStyle: {
        ...brandStyle,
        fontSize: '10px',
      },
      titleStyle: {
        ...titleStyle,
        fontSize: '10px',
      },
      priceStyle: {
        ...priceStyle,
        fontSize: '10px',
      },
    };

    // 検索キーワードシェルフ
    if (shelf.shelfType === ShelfTypeEnum.searchKeyword) {
      shelfOption.hasBannerTagline = true;
      shelfOption.bannerTaglineItemNum = 2;
      taglineOption.taglinesStyle = {
        ...taglineOption.taglinesStyle,
        width: '95%',
        margin: '10px auto 5px auto',
        borderRadius: '20px 20px 0 0',
      };
    }

    // 記事シェルフ
    if (shelf.itemKind === ItemKindEnum.Article) {
      shelfOption.gridColumns = 2;
      shelfOption.columnGap = 10;
      shelfOption.shelfStyle = {
        ...shelfOption.shelfStyle,
        padding: '10px 0px',
        background: '#f5f5f5',
      };
      itemOption.itemStyle = {
        ...itemOption.itemStyle,
        width: '100%',
        margin: '0 auto',
        marginBottom: '5px',
        borderRadius: '20px',
        overflow: 'hidden',
        background: '#fff',
      };
      itemOption.itemsStyle = {
        ...itemOption.itemsStyle,
        marginLeft: '0px',
        marginRight: '0px',
      };
      itemOption.itemScrollStyle = {
        ...itemOption.itemScrollStyle,
        gap: '15px',
      };
      itemOption.imageRatio = 100 / 150;
      itemOption.titleLineClamp = 2;
      itemOption.titleStyle = {
        ...itemOption.titleStyle,
        fontSize: '10px',
        padding: '5px 10px',
      };
    }

    return { shelfOption, taglineOption, itemOption };
  }
}
