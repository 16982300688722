import { CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { deleteStgSearchShelfQueries } from '../../services/shelf-builder/delete-stg-search-shelf-queries';
import { getStgSearchShelfQueries } from '../../services/shelf-builder/get-stg-search-shelf-queries';
import { postShelfItemsSearch } from '../../services/shelf-builder/post-shelf-items-search';
import { postStgSearchShelfQueries } from '../../services/shelf-builder/post-stg-search-shelf-queries';
import { postShelfTaglinesKnn } from '../../services/shelf-previewer/post-shelf-taglines-knn';
import { useClient } from '../../store/client.store';
import {
  IPostShelfItemSearchReq,
  IShelfSearchItem,
} from '../../types/post-shelf-item-search.type';
import { IStgSearchShelfQuery } from '../../types/stg-search-shelf-query.type';
import BulkRegistration from './BulkRegistration';
import InfoAccordions from './InfoAccordions';
import ShelfEditor from './ShelfEditor';
import {
  AddButton,
  Container,
  DeleteButton,
  Header,
  LoadingContainer,
} from './styles';

export interface ShelfState extends IStgSearchShelfQuery {
  updating: boolean;
  items?: IShelfSearchItem[];
  loading?: boolean;
  visibleItems?: IShelfSearchItem[];
}

export type SimilarShelvesState = Array<{ text: string; cossim_score: number }>;

const BATCH_SIZE = 5;
const INITIAL_VISIBLE_ITEMS = 30;

const ShelfBuilder: React.FC = () => {
  const { client: clientState } = useClient();
  const [shelves, setShelves] = useState<ShelfState[]>([]);
  const [similarShelves, setSimilarShelves] = useState<{
    [shelfId: string]: SimilarShelvesState;
  }>({});
  const [deletedShelvesExist, setDeletedShelvesExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQueue, setSearchQueue] = useState<string[]>([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);

  const processBatchSearch = useCallback(async () => {
    if (isProcessingQueue || searchQueue.length === 0) return;

    setIsProcessingQueue(true);
    const batch = searchQueue.slice(0, BATCH_SIZE);

    try {
      const searchPromises = batch.map(async (shelfId) => {
        const shelf = shelves.find((s) => s.shelf_id === shelfId);
        if (!shelf || !shelf.where_condition) return;

        setShelves((prevShelves) =>
          prevShelves.map((s) =>
            s.shelf_id === shelfId ? { ...s, loading: true } : s,
          ),
        );

        const dto: IPostShelfItemSearchReq = {
          where_condition: shelf.where_condition,
          order_by: 'pv_count_7d desc',
          limit: 300,
        };

        const searchRes = await postShelfItemsSearch(dto);
        const itemCount = searchRes.items?.length || 0;
        const shouldDelete = itemCount <= 10;

        const newIsDeleted = shelf.is_deleted
          ? shouldDelete
            ? true
            : shelf.is_deleted
          : shouldDelete;

        if (newIsDeleted !== shelf.is_deleted) {
          await postStgSearchShelfQueries([
            {
              ...shelf,
              is_deleted: newIsDeleted,
            },
          ]);
        }

        setShelves((prevShelves) =>
          prevShelves.map((s) =>
            s.shelf_id === shelfId
              ? {
                  ...s,
                  loading: false,
                  items: searchRes.items,
                  visibleItems: searchRes.items.slice(0, INITIAL_VISIBLE_ITEMS),
                  is_deleted: newIsDeleted,
                }
              : s,
          ),
        );
      });

      await Promise.all(searchPromises);
      setSearchQueue((prev) => prev.slice(BATCH_SIZE));
    } catch (error) {
      console.error('Error processing batch search:', error);
    } finally {
      setIsProcessingQueue(false);
    }
  }, [isProcessingQueue, searchQueue, shelves]);

  useEffect(() => {
    if (searchQueue.length > 0) {
      processBatchSearch();
    }
  }, [searchQueue, processBatchSearch]);

  const addToSearchQueue = useCallback((shelfId: string) => {
    setSearchQueue((prev) => {
      if (prev.includes(shelfId)) return prev;
      return [...prev, shelfId];
    });
  }, []);

  const handleLoadMore = useCallback((shelfId: string) => {
    setShelves((prevShelves) =>
      prevShelves.map((shelf) => {
        if (shelf.shelf_id !== shelfId) return shelf;
        const currentVisibleCount = shelf.visibleItems?.length || 0;
        return {
          ...shelf,
          visibleItems: shelf.items?.slice(0, currentVisibleCount + 10),
        };
      }),
    );
  }, []);

  const fetchSimilarShelves = useCallback(async (shelves: ShelfState[]) => {
    const taglines = shelves.map((shelf) => shelf.tagline_2);
    try {
      const knnResults = await postShelfTaglinesKnn(taglines);
      const newSimilarShelves: { [shelfId: string]: SimilarShelvesState } = {};
      shelves.forEach((shelf, index) => {
        newSimilarShelves[shelf.shelf_id] = knnResults[taglines[index]];
      });
      setSimilarShelves(newSimilarShelves);
    } catch (error) {
      console.error('Error fetching similar shelves:', error);
    }
  }, []);

  const fetchShelves = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getStgSearchShelfQueries();
      const initialShelfStates: ShelfState[] = data.map((shelfQuery) => ({
        ...shelfQuery,
        updating: false,
        items: [],
        loading: false,
        visibleItems: [],
      }));

      setShelves(initialShelfStates);

      const shelvesWithConditions = initialShelfStates.filter(
        (shelf) => shelf.where_condition,
      );
      setSearchQueue(shelvesWithConditions.map((shelf) => shelf.shelf_id));

      await fetchSimilarShelves(initialShelfStates);

      const hasDeleted = initialShelfStates.some((shelf) => shelf.is_deleted);
      setDeletedShelvesExist(hasDeleted);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchSimilarShelves]);

  useEffect(() => {
    fetchShelves();
  }, [fetchShelves, clientState.selectedClient]);

  const handleAddShelf = useCallback(async () => {
    const newShelfId = uuidv4();
    const newShelf: ShelfState = {
      shelf_id: newShelfId,
      tagline_1: '',
      tagline_2: '',
      where_condition: '',
      is_deleted: false,
      updating: false,
      item_count: 0,
      items: [],
      loading: false,
      visibleItems: [],
    };
    try {
      await postStgSearchShelfQueries([newShelf]);
      setShelves((prevShelves) => [newShelf, ...prevShelves]);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleShelfChange = useCallback((updatedShelf: ShelfState) => {
    setShelves((prevShelves) => {
      const newShelves = prevShelves.map((shelf) =>
        shelf.shelf_id === updatedShelf.shelf_id ? updatedShelf : shelf,
      );
      const hasDeleted = newShelves.some((shelf) => shelf.is_deleted);
      setDeletedShelvesExist(hasDeleted);
      return newShelves;
    });
  }, []);

  const handleDeleteShelves = useCallback(async () => {
    const shelfIdsToDelete = shelves
      .filter((shelf) => shelf.is_deleted)
      .map((shelf) => shelf.shelf_id);

    try {
      await deleteStgSearchShelfQueries(shelfIdsToDelete);
      setShelves((prevShelves) =>
        prevShelves.filter((shelf) => !shelf.is_deleted),
      );
      setDeletedShelvesExist(false);
    } catch (error) {
      console.error(error);
    }
  }, [shelves]);

  const handleBulkRegistration = useCallback(
    async (newShelves: ShelfState[]) => {
      try {
        await postStgSearchShelfQueries(newShelves);
        setShelves((prevShelves) => [...newShelves, ...prevShelves]);
        await fetchSimilarShelves([...newShelves, ...shelves]);
      } catch (error) {
        console.error(error);
      }
    },
    [shelves, fetchSimilarShelves],
  );

  const updateSimilarShelves = useCallback(
    (
      shelfId: string,
      similarShelvesData: Array<{ text: string; cossim_score: number }>,
    ) => {
      setSimilarShelves((prevSimilarShelves) => ({
        ...prevSimilarShelves,
        [shelfId]: similarShelvesData,
      }));
    },
    [],
  );

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <Header>
        <Typography
          variant="h4"
          component="h1"
          style={{ fontWeight: 'bold', color: '#333' }}
        >
          シェルフビルダー
        </Typography>
        <div>
          <AddButton variant="contained" onClick={handleAddShelf}>
            追加する
          </AddButton>
          {deletedShelvesExist && (
            <DeleteButton
              variant="outlined"
              color="secondary"
              onClick={handleDeleteShelves}
            >
              削除
            </DeleteButton>
          )}
        </div>
      </Header>
      <InfoAccordions />
      <BulkRegistration onNewShelvesAdded={handleBulkRegistration} />
      {shelves.map((shelf) => (
        <ShelfEditor
          key={shelf.shelf_id}
          shelf={shelf}
          onChange={handleShelfChange}
          updateSimilarShelves={updateSimilarShelves}
          similarShelves={similarShelves[shelf.shelf_id] || []}
          onSearch={() => addToSearchQueue(shelf.shelf_id)}
          onLoadMore={() => handleLoadMore(shelf.shelf_id)}
        />
      ))}
    </Container>
  );
};

export default ShelfBuilder;
