import { Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { postStgSearchShelfQueries } from '../../services/shelf-builder/post-stg-search-shelf-queries';
import { IStgSearchShelfQuery } from '../../types/stg-search-shelf-query.type';
import { ShelfState } from './ShelfBuilder';

const Container = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;
`;

const PreviewContainer = styled.div`
  margin-bottom: 16px;
`;

const ShelfPreview = styled.div`
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface BulkRegistrationProps {
  onNewShelvesAdded: (newShelves: ShelfState[]) => void;
}

const BulkRegistration: React.FC<BulkRegistrationProps> = ({
  onNewShelvesAdded,
}) => {
  const [bulkText, setBulkText] = useState('');
  const [newShelves, setNewShelves] = useState<IStgSearchShelfQuery[]>([]);

  const parseBulkText = useCallback((text: string) => {
    const lines = text.replace(/\t/g, ' ').replace(/	/g, ' ').split('\n');
    const headerNum = lines[0].split(' ').filter((v) => v !== '').length;
    const parsedShelves: IStgSearchShelfQuery[] = lines
      .slice(1)
      .map((line) => {
        const parts = line.split(' ');
        if (headerNum === 2) {
          return {
            shelf_id: uuidv4(),
            tagline_2: parts[0],
            where_condition: parts.slice(1).join(' '),
            tagline_1: '',
            is_deleted: false,
            item_count: 0,
          };
        } else if (headerNum === 3) {
          return {
            shelf_id: uuidv4(),
            tagline_1: parts[0],
            tagline_2: parts[1],
            where_condition: parts.slice(2).join(' '),
            is_deleted: false,
            item_count: 0,
          };
        }
        return null;
      })
      .filter((shelf): shelf is IStgSearchShelfQuery => shelf !== null);
    return parsedShelves;
  }, []);

  const handleBulkTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;
      setBulkText(text);

      // テキストをパースしてプレビュー用の新しいシェルフを作成
      const parsedShelves = parseBulkText(text);
      setNewShelves(parsedShelves);
    },
    [parseBulkText],
  );

  const handleBulkRegistration = useCallback(async () => {
    try {
      await postStgSearchShelfQueries(newShelves);
      const newShelfStates: ShelfState[] = newShelves.map((shelf) => ({
        ...shelf,
        aids: [],
        items: [],
        pv_count_7d_ranks: [],
        loading: false,
        updating: false,
      }));
      onNewShelvesAdded(newShelfStates);
      setBulkText('');
      setNewShelves([]);
    } catch (error) {
      console.error(error);
    }
  }, [newShelves, onNewShelvesAdded]);

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        一括登録
      </Typography>
      <StyledTextField
        label="シェルフ情報"
        multiline
        rows={10}
        value={bulkText}
        onChange={handleBulkTextChange}
        placeholder={`訴求文 WHERE句
1枚でサマになるワンピース sub_category = 'ワンピース' AND (item_title LIKE '%ドレス%')
着まわし力抜群のロゴTシャツ sub_category = 'Tシャツ・カットソー' AND item_title LIKE '%ロゴTシャツ%'`}
      />
      {/* プレビュー表示 */}
      <PreviewContainer>
        {newShelves.map((shelf, index) => (
          <ShelfPreview key={index}>
            <Typography variant="subtitle1">
              タグライン1: {shelf.tagline_1}
            </Typography>
            <Typography variant="subtitle1">
              タグライン2: {shelf.tagline_2}
            </Typography>
            <Typography variant="body2">
              WHERE句: {shelf.where_condition}
            </Typography>
          </ShelfPreview>
        ))}
      </PreviewContainer>
      <ButtonContainer>
        <Button
          variant="contained"
          onClick={handleBulkRegistration}
          disabled={newShelves.length === 0}
        >
          一括登録
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default BulkRegistration;
