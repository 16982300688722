import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ItemKindEnum } from '../../enums/item-kind.enum';
import { IItem } from '../../insightx-utils/types/item.type';
import { IShelf } from '../../insightx-utils/types/shelf.type';
import { ShelfStyleUtil } from '../../insightx-utils/utils/shelf-style-util';
import Banner from './Banner';

interface GridShelfProps {
  shelf: IShelf;
}

const TaglinesContainer = styled.div<{
  customstyle?: string;
}>`
  ${(props) => props.customstyle || ''}
`;

const Tagline1 = styled.div<{
  customstyle?: string;
}>`
  ${(props) => props.customstyle || ''}
`;

const Tagline2 = styled.div<{
  customstyle?: string;
}>`
  ${(props) => props.customstyle || ''}
`;

const ItemsContainer = styled.div<{
  customstyle?: string;
}>`
  ${(props) => props.customstyle || ''}
`;

const ItemScroll = styled.div<{
  customstyle?: string;
}>`
  ${(props) => props.customstyle || ''}
`;

const Item = styled.a<{
  customstyle?: string;
}>`
  color: inherit;
  height: auto;
  ${(props) => props.customstyle || ''}
  text-decoration: none;
`;

const ItemImg = styled.img<{
  customstyle?: string;
}>`
  width: 100%;
  ${(props) => props.customstyle || ''}
`;

const InfoContainer = styled.div<{
  customstyle?: string;
}>`
  ${(props) => props.customstyle || ''}
`;

const Brand = styled.div<{
  customstyle?: string;
  lineClamp?: number;
}>`
  ${(props) => props.customstyle || ''}
`;

const Title = styled.div<{
  customstyle?: string;
  lineClamp?: number;
}>`
  ${(props) => props.customstyle || ''}
`;

const Price = styled.div<{
  customstyle?: string;
}>`
  ${(props) => props.customstyle || ''}
`;

const CoordInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const CoordUserImageContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
`;

const CoordUserImage = styled.img`
  width: 100%;
  height: 100%;
`;

const CoordUserInfoContainer = styled.div`
  display: flex;
  width: calc(100% - 60px);
  flex-direction: column;
  margin-left: 10px;
`;

const CoordLabelName = styled.div`
  font-size: 10px;
`;

const CoordUserName = styled.div`
  font-size: 10px;
`;

const CoordShopName = styled.div`
  font-size: 10px;
`;

const ShowMoreButton = styled.button`
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const GridShelf: React.FC<GridShelfProps> = ({ shelf }) => {
  const [items, setItems] = useState<IItem[]>(shelf.items);
  const [visibleItems, setVisibleItems] = useState<IItem[]>([]);
  const [hasBannerTagline, setHasBannerTagline] = useState<boolean>(false);
  const [bannerItems, setBannerItems] = useState<IItem[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    setItems(shelf.items);
    setVisibleItems(shelf.items.slice(0, shelf.firstItemNum));
  }, [shelf.items, shelf.firstItemNum]);

  useEffect(() => {
    const bannerItemNum = shelf.shelfOption.bannerTaglineItemNum || 2;
    setHasBannerTagline(shelf.shelfOption.hasBannerTagline);
    setBannerItems(
      items
        .slice(0, 10)
        .sort(() => 0.5 - Math.random())
        .slice(0, bannerItemNum),
    );
  }, [
    shelf.items,
    shelf.shelfOption.hasBannerTagline,
    shelf.shelfOption.bannerTaglineItemNum,
  ]);

  const handleImageError = (aid: string) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.filter((item) => item.aid !== aid);
      setVisibleItems(updatedItems.slice(0, shelf.firstItemNum));
      return updatedItems;
    });
  };

  const handleShowMore = () => {
    setVisibleItems(items);
    setShowMore(true);
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      {hasBannerTagline ? (
        <Banner shelf={shelf} items={bannerItems} />
      ) : (
        <TaglinesContainer
          customstyle={ShelfStyleUtil.getShelfStyle(shelf, 'taglines')}
        >
          {shelf.tagline1 && (
            <Tagline1
              customstyle={ShelfStyleUtil.getShelfStyle(shelf, 'tagline1')}
            >
              {shelf.tagline1}
            </Tagline1>
          )}
          {shelf.tagline2 && (
            <Tagline2
              customstyle={ShelfStyleUtil.getShelfStyle(shelf, 'tagline2')}
            >
              {shelf.tagline2}
            </Tagline2>
          )}
        </TaglinesContainer>
      )}
      <ItemsContainer
        customstyle={ShelfStyleUtil.getShelfStyle(shelf, 'items')}
      >
        <ItemScroll
          customstyle={ShelfStyleUtil.getShelfStyle(shelf, 'itemScroll')}
        >
          {visibleItems.map((item) => {
            return (
              <Item
                key={item.aid}
                customstyle={ShelfStyleUtil.getShelfStyle(shelf, 'item')}
                href={item.url}
                target="_blank"
              >
                <div>
                  <ItemImg
                    src={item.imageUrl}
                    alt={item.title}
                    loading="lazy"
                    onError={() => {
                      handleImageError(item.aid);
                    }}
                    customstyle={ShelfStyleUtil.getShelfStyle(shelf, 'image')}
                  />
                  <InfoContainer
                    customstyle={ShelfStyleUtil.getShelfStyle(shelf, 'info')}
                  >
                    {item.brand && (
                      <Brand
                        customstyle={ShelfStyleUtil.getShelfStyle(
                          shelf,
                          'brand',
                        )}
                        lineClamp={shelf.itemOption.brandLineClamp}
                      >
                        {item.brand}
                      </Brand>
                    )}
                    {item.title && (
                      <Title
                        customstyle={ShelfStyleUtil.getShelfStyle(
                          shelf,
                          'title',
                        )}
                        lineClamp={shelf.itemOption.titleLineClamp}
                      >
                        {item.title}
                      </Title>
                    )}
                    {item.price && (
                      <Price
                        customstyle={ShelfStyleUtil.getShelfStyle(
                          shelf,
                          'price',
                        )}
                      >
                        ¥{item.price.toLocaleString()}
                      </Price>
                    )}
                    {shelf.itemKind === ItemKindEnum.Coordinate && (
                      <CoordInfoContainer>
                        <CoordUserImageContainer>
                          <CoordUserImage
                            src={item.userImageUrl}
                            alt={item.userName}
                          />
                        </CoordUserImageContainer>
                        <CoordUserInfoContainer>
                          <CoordLabelName>{item.labelName}</CoordLabelName>
                          <CoordUserName>{item.userName}</CoordUserName>
                          <CoordShopName>{item.shopName}</CoordShopName>
                        </CoordUserInfoContainer>
                      </CoordInfoContainer>
                    )}
                  </InfoContainer>
                </div>
              </Item>
            );
          })}
        </ItemScroll>
      </ItemsContainer>
      {!showMore && items.length > shelf.firstItemNum && (
        <ShowMoreButton onClick={handleShowMore}>もっと見る</ShowMoreButton>
      )}
    </>
  );
};

export default GridShelf;
