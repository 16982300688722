import { IShelf } from '../../types/shelf.type';
import { IStyle } from '../../types/style.type';
import { ShelfStyleKind } from './shelf-style-kind.type';

type StyleOption =
  | 'shelfStyle'
  | 'taglinesStyle'
  | 'tagline1Style'
  | 'tagline2Style'
  | 'itemsStyle'
  | 'itemScrollStyle'
  | 'itemStyle'
  | 'imageStyle'
  | 'infoStyle'
  | 'brandStyle'
  | 'titleStyle'
  | 'priceStyle'
  | 'salePriceStyle'
  | 'iconsStyle'
  | 'iconStyle';

const styleMap: Record<ShelfStyleKind, [keyof IShelf, StyleOption]> = {
  [ShelfStyleKind.Shelf]: ['shelfOption', 'shelfStyle'],
  [ShelfStyleKind.Taglines]: ['taglineOption', 'taglinesStyle'],
  [ShelfStyleKind.Tagline1]: ['taglineOption', 'tagline1Style'],
  [ShelfStyleKind.Tagline2]: ['taglineOption', 'tagline2Style'],
  [ShelfStyleKind.Items]: ['itemOption', 'itemsStyle'],
  [ShelfStyleKind.ItemScroll]: ['itemOption', 'itemScrollStyle'],
  [ShelfStyleKind.Item]: ['itemOption', 'itemStyle'],
  [ShelfStyleKind.Image]: ['itemOption', 'imageStyle'],
  [ShelfStyleKind.Info]: ['itemOption', 'infoStyle'],
  [ShelfStyleKind.Brand]: ['itemOption', 'brandStyle'],
  [ShelfStyleKind.Title]: ['itemOption', 'titleStyle'],
  [ShelfStyleKind.Price]: ['itemOption', 'priceStyle'],
  [ShelfStyleKind.SalePrice]: ['itemOption', 'salePriceStyle'],
  [ShelfStyleKind.Icons]: ['itemOption', 'iconsStyle'],
  [ShelfStyleKind.Icon]: ['itemOption', 'iconStyle'],
};

export function setShelfStyle<K extends keyof IStyle>(
  shelf: IShelf,
  styleKind: ShelfStyleKind,
  key: K,
  value: IStyle[K],
  force: boolean = false,
): IShelf {
  const [optionKey, styleKey] = styleMap[styleKind];
  const option = shelf[optionKey];

  if (option) {
    const style = option[styleKey] as IStyle | undefined;
    if (!style?.[key] || force) {
      option[styleKey] = {
        ...(style || {}),
        [key]: value,
      } as IStyle;
    }
  }

  return shelf;
}
