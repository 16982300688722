import { IShelfConfig } from '../../types/shelf-config.type';
import { Config } from '../config/config';
import { DomService } from '../dom/dom.service';
import { FetchService } from '../fetch/fetch.service';
import { BellunaPreprocessService } from '../preprocess/custom/belluna-preprocess.service';
import { CanPreprocessService } from '../preprocess/custom/can-preprocess.service';
import { MaruiPreprocessService } from '../preprocess/custom/marui-preprocess.service';
import { OnwardPreprocessService } from '../preprocess/custom/onward-preprocess.service';
import { PalPreprocessService } from '../preprocess/custom/pal-preprocess.service';
import { UrbanPreprocessService } from '../preprocess/custom/urban-preprocess.service';
import { PreprocessServiceBase } from '../preprocess/preprocess.base.service';
import type { IPreprocessService } from '../preprocess/preprocess.service.interface';
import { ShelfService } from '../shelf/shelf.service';

export class ShelfContainer {
  client: string;
  clientId: string;
  config: Config;

  shelfConfig: IShelfConfig;
  session: string;
  seedAids: string[];

  shelfService: ShelfService;
  domService: DomService;
  fetchService: FetchService;
  preprocessService: IPreprocessService;

  constructor(
    client: string,
    clientId: string,
    shelfConfig: IShelfConfig,
    session?: string,
    seedAids?: string[],
  ) {
    this.client = client;
    this.clientId = clientId;
    this.shelfConfig = shelfConfig;
    this.session = session;
    this.seedAids = seedAids;
  }

  async init(): Promise<void> {
    this.config = new Config();
    await this.config.init();

    this.domService = new DomService();

    this.fetchService = new FetchService(
      this.clientId,
      this.session,
      this.seedAids,
      this.shelfConfig,
    );

    this.preprocessService = new PreprocessServiceBase(this.config);
    if (this.client === 'キャン様') {
      this.preprocessService = new CanPreprocessService(this.config);
    }
    if (this.client === 'オンワード様') {
      this.preprocessService = new OnwardPreprocessService(this.config);
    }
    if (this.client === 'パル様') {
      this.preprocessService = new PalPreprocessService(this.config);
    }
    if (this.client === 'ベルーナ様') {
      this.preprocessService = new BellunaPreprocessService(this.config);
    }
    if (this.client === '丸井様') {
      this.preprocessService = new MaruiPreprocessService(this.config);
    }
    if (this.client === 'アーバンリサーチ様') {
      this.preprocessService = new UrbanPreprocessService(this.config);
    }

    this.shelfService = new ShelfService(
      this.config,
      this.domService,
      this.fetchService,
      this.preprocessService,
    );
  }
}
