import React from 'react';
import styled from 'styled-components';
import { IItem } from '../../insightx-utils/types/item.type';
import { IShelf } from '../../insightx-utils/types/shelf.type';
import { ShelfStyleUtil } from '../../insightx-utils/utils/shelf-style-util';

interface BannerProps {
  shelf: IShelf;
  items: IItem[];
}

const BannerContainer = styled.div<{
  sx?: string;
  afterSx?: string;
}>`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  ${(props) => props.sx || ''}

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(
      180deg,
      transparent 0%,
      rgba(0, 0, 0, 0.1) 20%,
      rgba(0, 0, 0, 0.7) 100%
    );
    pointer-events: none;
    ${(props) => props.afterSx || ''}
  }
`;

const ImageContainer = styled.a`
  text-decoration: none;
  display: flex;
  width: 100%;
  height: 100%;
`;

const Image = styled.div<{ url: string }>`
  flex: 1;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
`;

const TaglineContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Tagline1 = styled.h2<{
  sx?: string;
}>`
  font-size: 15px;
  margin: 0;
  ${(props) => props.sx || ''}
`;

const Tagline2 = styled.h3<{
  sx?: string;
}>`
  font-size: 18px;
  margin: 5px 0 0;
  ${(props) => props.sx || ''}
`;

const Banner: React.FC<BannerProps> = ({ shelf, items }) => {
  return (
    <BannerContainer sx={ShelfStyleUtil.getShelfStyle(shelf, 'taglines')}>
      <ImageContainer href={shelf.showMoreLink || '#'} target="_blank">
        {items.slice(0, 2).map((item, index) => (
          <Image key={index} url={item.imageUrl || ''} />
        ))}
      </ImageContainer>
      <TaglineContainer>
        {shelf.tagline1 && (
          <Tagline1 sx={ShelfStyleUtil.getShelfStyle(shelf, 'tagline1')}>{shelf.tagline1}</Tagline1>
        )}
        {shelf.tagline2 && (
          <Tagline2 sx={ShelfStyleUtil.getShelfStyle(shelf,'tagline2' )}>{shelf.tagline2}</Tagline2>
        )}
      </TaglineContainer>
    </BannerContainer>
  );
};

export default Banner;
