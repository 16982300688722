import React, { useEffect, useState } from 'react';
import { getBrandItems } from '../../services/shelf-previewer/get-brand-items';
import { useClient } from '../../store/client.store';
import { IBrandItem } from '../../types/brand-item.type';
import { IShelfConfig } from '../../types/shelf-config.type';
import BrandGrid from './BrandGrid';
import { CircularProgress } from '@mui/material';
import styled, { createGlobalStyle } from 'styled-components';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';
import { RecommendLogicEnum } from '../../enums/recommend-logic.enum';
import ShelfConfigBuilder from '../shelf-config-builder/ShelfConfigBuilder';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px;
`;

const LoadMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e65c00;
  }
`;

const ItemShelfPreviewer: React.FC = () => {
  const [brandItems, setBrandItems] = useState<IBrandItem[]>([]);
  const [visibleBrands, setVisibleBrands] = useState(6);
  const { client: clientState } = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [shelfConfig, setShelfConfig] = useState<IShelfConfig>({
    historyFilteredItemNum: 100,
    shelfOrders: [
      {
        shelfType: ShelfTypeEnum.related,
        shelfNum: 1,
        recommendLogic: RecommendLogicEnum.Covis,
        maxItems: 50,
      },
    ],
  });

  // ブランドアイテムを取得
  useEffect(() => {
    const fetchBrandItems = async () => {
      setIsLoading(true);
      try {
        const res = await getBrandItems();
        setBrandItems(res);
      } catch (error) {
        console.error('ブランドアイテムの取得に失敗しました:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBrandItems();
  }, [clientState.selectedClient.clientId]);

  // shelfConfigの変更ハンドラー
  const handleConfigChange = (newConfig: IShelfConfig) => {
    setShelfConfig(newConfig);
  };

  return (
    <PageContainer>
      <GlobalStyle />
      {/* ShelfConfigBuilderの組み込み */}
      <ShelfConfigBuilder
        shelfConfig={shelfConfig}
        onConfigChange={handleConfigChange}
        baseType="item-based"
      />
      {/* ローディングインジケーター */}
      {isLoading && <CircularProgress />}
      {/* ブランドグリッド */}
      <BrandGrid
        brandItems={brandItems.slice(0, visibleBrands)}
        shelfConfig={shelfConfig}
      />
      {/* もっと見るボタン */}
      {visibleBrands < brandItems.length && (
        <LoadMoreButton onClick={() => setVisibleBrands((prev) => prev + 6)}>
          もっと見る
        </LoadMoreButton>
      )}
    </PageContainer>
  );
};

export default ItemShelfPreviewer;
