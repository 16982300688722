import { IScrapedListPage } from '../../types/list-page.type';
import api from '../api';

export const scrapeListPage = async (
  url: string,
): Promise<IScrapedListPage> => {
  const res = await api.post(`/page/list_pages/scrape_list_page`, {
    url,
  });
  return res.data;
};
