/* eslint-disable @typescript-eslint/no-unused-vars */
import { ShelfUiTypeEnum } from '../../../enums/shelf-ui-type.enum';
import type { IItemOption } from '../../../types/item-option.type';
import type { IShelfOption } from '../../../types/shelf-option.type';
import type { IShelf } from '../../../types/shelf.type';
import type { ITaglineOption } from '../../../types/tagline-option.type';
import { PreprocessServiceBase } from '../preprocess.base.service';

export class CanPreprocessService extends PreprocessServiceBase {
  private fontFamily =
    '"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","MS PGothic",Sans-Serif';

  async getShelfOverride(shelf: IShelf): Promise<IShelf> {
    const uiType: ShelfUiTypeEnum = ShelfUiTypeEnum.slider;
    return { uiType };
  }

  async getOptions(shelf: IShelf): Promise<{
    shelfOption: IShelfOption;
    taglineOption: ITaglineOption;
    itemOption: IItemOption;
  }> {
    shelf.shelfOption.fontFamily = this.fontFamily;
    const {
      shelfStyle,
      tagline1Style,
      tagline2Style,
      itemsStyle,
      itemScrollStyle,
      titleStyle,
      brandStyle,
      priceStyle,
    } = await super.getBaseStyles(shelf);
    const shelfOption: IShelfOption = {
      shelfStyle: {
        ...shelfStyle,
        marginBottom: '10px',
      },
    };
    const taglineOption: ITaglineOption = {
      taglinesStyle: {
        marginLeft: '13px',
      },
      tagline1Style: {
        ...tagline1Style,
        fontSize: '13px',
        color: '#828282',
        fontWeight: 'bold',
      },
      tagline2Style: {
        ...tagline2Style,
        fontSize: '16px',
        color: '#000',
        fontWeight: 'bold',
      },
    };
    const itemOption: IItemOption = {
      widthVw: 30,
      imageRatio: 320.0 / 240.0,
      maxWidth: 184,
      titleLineClamp: 1,
      brandLineClamp: 1,
      itemsStyle: {
        ...itemsStyle,
      },
      itemScrollStyle: {
        ...itemScrollStyle,
        marginLeft: '13px',
        marginRight: '13px',
      },
      itemStyle: {
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.1)',
        background: '#fff',
      },
      imageStyle: {
        borderRadius: '10px',
        overflow: 'hidden',
      },
      infoStyle: {
        padding: '6px',
      },
      brandStyle: {
        ...brandStyle,
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#000',
      },
      titleStyle: {
        ...titleStyle,
        fontSize: '11px',
        color: '#828282',
      },
      priceStyle: {
        ...priceStyle,
        fontSize: '11px',
      },
    };

    return { shelfOption, taglineOption, itemOption };
  }
}
