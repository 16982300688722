import api from '../../../services/api';
import type { IPostRecommendsResDto } from '../../dto/post-recommends.dto';
import { PageTypeEnum } from '../../enums/page-type.enum';
import type { IShelfConfig } from '../../types/shelf-config.type';
import type { IFetchService } from './fetch.service.interface';

export class FetchService implements IFetchService {
  private clientId: string;
  private session: string;
  private seedAids: string[];
  private shelfConfig: IShelfConfig;
  constructor(
    clientId: string,
    session: string,
    seedAids: string[] | undefined,
    shelfConfig: IShelfConfig,
  ) {
    this.clientId = clientId;
    this.session = session;
    this.seedAids = seedAids;
    this.shelfConfig = shelfConfig;
  }

  async fetchShelves(): Promise<IPostRecommendsResDto> {
    const res = (
      await api.post('/recommends/recommends', {
        clientId: await this.getClientId(),
        pageType: await this.getPageType(),
        session: await this.getSession(),
        gender: await this.getGender(),
        abFlag: await this.getAbFlag(),
        abFlag2: await this.getAbFlag2(),
        abFlag3: await this.getAbFlag3(),
        seedAids: await this.getSeedAids(),
        aids: await this.getAids(),
        targetAids: await this.getTargetAids(),
        filteredAids: await this.getFilteredAids(),
        colors: await this.getColors(),
        brandUrlCode: await this.getBrandUrlCode(),
        brand: await this.getBrand(),
        categoryCode: await this.getCategoryCode(),
        subCategoryCode: await this.getSubCategoryCode(),
        shelfConfig: await this.getShelfConfig(),
      })
    ).data;
    return res;
  }

  async getClientId(): Promise<string> {
    return this.clientId;
  }

  async getPageType(): Promise<PageTypeEnum> {
    return PageTypeEnum.Top;
  }

  async getSession(): Promise<string> {
    return this.session;
  }

  async getGender(): Promise<string> {
    return undefined;
  }

  async getAbFlag(): Promise<string> {
    return undefined;
  }

  async getAbFlag2(): Promise<string> {
    return undefined;
  }

  async getAbFlag3(): Promise<string> {
    return undefined;
  }

  async getSeedAids(): Promise<string[]> {
    return this.seedAids;
  }

  async getAids(): Promise<string[]> {
    return undefined;
  }

  async getShelfConfig(): Promise<IShelfConfig> {
    return this.shelfConfig;
  }

  async getTargetAids(): Promise<string[]> {
    return undefined;
  }

  async getFilteredAids(): Promise<string[]> {
    return undefined;
  }

  async getColors(): Promise<string[]> {
    return undefined;
  }

  async getBrandUrlCode(): Promise<string> {
    return undefined;
  }

  async getBrand(): Promise<string> {
    return undefined;
  }

  async getCategoryCode(): Promise<string> {
    return undefined;
  }

  async getSubCategoryCode(): Promise<string> {
    return undefined;
  }
}
