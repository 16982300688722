import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import { ChevronDown, ChevronRight, Info } from 'lucide-react';
import React, { useState } from 'react';
import { ComponentMetrics } from '../../services/component-service';
import {
  getPageTypeDescription,
  getPageTypeLabel,
} from '../../utils/page-type-utils';

interface IXSummaryTableProps {
  metrics: ComponentMetrics[];
  startDate: string;
  endDate: string;
}

interface PageSummary {
  pageType: string;
  componentCount: number;
  shelfCount: number;
  shown: number;
  click: number;
  cv: number;
  ctr: number;
  cvr: number;
  revenue: number;
  shelves?: ComponentMetrics[];
}

interface Column {
  id: keyof PageSummary;
  label: string;
  tooltip: string;
  format: (value: any) => string | number;
  showBar?: boolean;
  getBarMaxValue?: (summaries: PageSummary[]) => number;
}

const IXSummaryTable: React.FC<IXSummaryTableProps> = ({
  metrics,
  startDate,
  endDate,
}) => {
  const [expandedPage, setExpandedPage] = useState<string | null>(null);

  // 日数の差分を計算
  const daysDiff = differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;

  // Helper functions
  const safeNumber = (value: number | null | undefined): number => {
    if (value === null || value === undefined || isNaN(value)) return 0;
    return value;
  };

  const safeDivide = (numerator: number, denominator: number): number => {
    if (denominator === 0 || !denominator) return 0;
    const result = numerator / denominator;
    return isFinite(result) ? result : 0;
  };

  // 月間換算売上の計算
  const calculateMonthlyRevenue = (cv: number): number => {
    const dailyRevenue = (cv * 10000) / daysDiff;
    return dailyRevenue * 30;
  };

  // Calculate summary for each page type with shelf details
  const summaries: PageSummary[] = Object.entries(
    metrics.reduce((acc: Record<string, ComponentMetrics[]>, metric) => {
      if (metric.component?.startsWith('IX')) {
        const pageType = metric.url_page_type;
        if (!acc[pageType]) acc[pageType] = [];
        acc[pageType].push(metric);
      }
      return acc;
    }, {}),
  )
    .map(([pageType, pageMetrics]) => {
      const shown = pageMetrics.reduce(
        (max, m) => Math.max(max, safeNumber(m.shown)),
        0,
      );
      const click = pageMetrics.reduce(
        (sum, m) => sum + safeNumber(m.click),
        0,
      );
      const cv = pageMetrics.reduce((sum, m) => sum + safeNumber(m.cv), 0);
      const shelfCount = pageMetrics.filter((m) => m.type === 'shelf').length;
      const componentCount = pageMetrics.filter(
        (m) => m.type === 'component',
      ).length;

      const shelves = pageMetrics
        .filter((m) => m.type === 'shelf')
        .map((m) => ({
          ...m,
          ctr: safeDivide(m.click, m.shown),
          cvr: safeDivide(m.cv, m.click),
          revenue: calculateMonthlyRevenue(m.cv),
        }))
        .sort((a, b) => (b.revenue || 0) - (a.revenue || 0));

      return {
        pageType,
        componentCount,
        shelfCount,
        shown,
        click,
        cv,
        ctr: safeDivide(click, shown),
        cvr: safeDivide(cv, click),
        revenue: calculateMonthlyRevenue(cv),
        shelves,
      };
    })
    .sort((a, b) => b.revenue - a.revenue);

  const columns: Column[] = [
    {
      id: 'pageType',
      label: 'ページタイプ',
      tooltip: 'コンポーネントが設置されているページの種類',
      format: (value: string) => value,
    },
    {
      id: 'shown',
      label: '表示数',
      tooltip: '期間中にユーザーの画面に表示された回数',
      format: (value: number) => value.toLocaleString(),
      showBar: true,
      getBarMaxValue: (summaries) => Math.max(...summaries.map((s) => s.shown)),
    },
    {
      id: 'shelfCount',
      label: 'シェルフ数',
      tooltip: 'InsightXシェルフの種類数',
      format: (value: number) => value,
    },
    {
      id: 'click',
      label: 'クリック数',
      tooltip: 'ユーザーがコンポーネントをクリックした回数',
      format: (value: number) => value.toLocaleString(),
      showBar: true,
      getBarMaxValue: (summaries) => Math.max(...summaries.map((s) => s.click)),
    },
    {
      id: 'ctr',
      label: 'クリック率',
      tooltip: '表示数に対するクリック数の割合 (クリック数÷表示数)',
      format: (value: number) => `${(value * 100).toFixed(1)}%`,
      showBar: true,
      getBarMaxValue: (summaries) =>
        Math.max(...summaries.map((s) => s.ctr)) * 1.25, // 最大値の80%を上限に
    },
    {
      id: 'cv',
      label: 'CV数',
      tooltip: '商品購入などのコンバージョンが発生した数',
      format: (value: number) => value.toLocaleString(),
      showBar: true,
      getBarMaxValue: (summaries) => Math.max(...summaries.map((s) => s.cv)),
    },
    {
      id: 'cvr',
      label: 'CVR',
      tooltip: 'クリック数に対するコンバージョン数の割合 (CV数÷クリック数)',
      format: (value: number) => `${(value * 100).toFixed(1)}%`,
      showBar: true,
      getBarMaxValue: (summaries) =>
        Math.max(...summaries.map((s) => s.cvr)) * 1.25, // 最大値の80%を上限に
    },
    {
      id: 'revenue',
      label: '月間換算売上',
      tooltip: '1CVあたり10,000円で計算した月間の想定売上額',
      format: (value: number) => `¥${value.toLocaleString()}`,
      showBar: true,
      getBarMaxValue: (summaries) =>
        Math.max(...summaries.map((s) => s.revenue)),
    },
  ];

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        overflow: 'hidden',
      }}
    >
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.id === 'pageType' ? 'left' : 'right'}
                sx={{
                  whiteSpace: 'nowrap',
                  bgcolor: 'background.paper',
                  borderBottom: '2px solid',
                  borderColor: 'divider',
                  position: column.id === 'pageType' ? 'sticky' : 'static',
                  left: column.id === 'pageType' ? 0 : 'auto',
                  zIndex: column.id === 'pageType' ? 2 : 1,
                  minWidth: column.id === 'pageType' ? '200px' : 'auto',
                  boxShadow:
                    column.id === 'pageType'
                      ? '4px 0 8px -4px rgba(0,0,0,0.1)'
                      : 'none',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:
                      column.id === 'pageType' ? 'flex-start' : 'flex-end',
                    gap: 0.5,
                  }}
                >
                  {column.label}
                  <Tooltip title={column.tooltip} arrow placement="top">
                    <Info size={16} color="#666" />
                  </Tooltip>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {summaries.map((summary) => (
            <React.Fragment key={summary.pageType}>
              <TableRow
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'action.hover',
                    '& .metrics-bar': { opacity: 0.15 },
                  },
                }}
                onClick={() =>
                  setExpandedPage(
                    expandedPage === summary.pageType ? null : summary.pageType,
                  )
                }
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.id === 'pageType' ? 'left' : 'right'}
                    sx={{
                      position:
                        column.id === 'pageType' ? 'sticky' : 'relative',
                      left: column.id === 'pageType' ? 0 : 'auto',
                      bgcolor:
                        column.id === 'pageType'
                          ? 'primary.lighter'
                          : 'background.paper',
                      zIndex: column.id === 'pageType' ? 1 : 0,
                      py: 2,
                      borderRight:
                        column.id === 'pageType' ? '1px solid' : 'none',
                      borderRightColor: 'divider',
                      boxShadow:
                        column.id === 'pageType'
                          ? '4px 0 8px -4px rgba(0,0,0,0.1)'
                          : 'none',
                    }}
                  >
                    {column.id === 'pageType' ? (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <IconButton size="small">
                          {expandedPage === summary.pageType ? (
                            <ChevronDown size={20} />
                          ) : (
                            <ChevronRight size={20} />
                          )}
                        </IconButton>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 'bold', color: 'primary.main' }}
                          >
                            {getPageTypeLabel(summary.pageType)}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: 'text.secondary', display: 'block' }}
                          >
                            {getPageTypeDescription(summary.pageType)}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        {column.showBar && (
                          <Box
                            className="metrics-bar"
                            sx={{
                              position: 'absolute',
                              left: 0,
                              top: 0,
                              bottom: 0,
                              width: `${((summary[column.id] as number) / (column.getBarMaxValue?.(summaries) || 1)) * 100}%`,
                              bgcolor: 'primary.main',
                              opacity: 0.1,
                              transition: 'opacity 0.2s',
                            }}
                          />
                        )}
                        <Typography
                          variant="body2"
                          sx={{ position: 'relative', fontWeight: 'medium' }}
                        >
                          {column.format(summary[column.id])}
                        </Typography>
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell colSpan={columns.length} sx={{ p: 0 }}>
                  <Collapse in={expandedPage === summary.pageType}>
                    <Box sx={{ bgcolor: 'grey.50', py: 1 }}>
                      <Table size="small">
                        <TableBody>
                          {summary.shelves?.map((shelf) => (
                            <TableRow
                              key={shelf.component}
                              sx={{ '&:hover': { bgcolor: 'action.hover' } }}
                            >
                              <TableCell
                                sx={{
                                  pl: 8,
                                  position: 'sticky',
                                  left: 0,
                                  bgcolor: 'grey.50',
                                  zIndex: 1,
                                  borderRight: '1px solid',
                                  borderRightColor: 'divider',
                                }}
                              >
                                <Typography variant="body2">
                                  {shelf.component}
                                </Typography>
                              </TableCell>
                              {columns.slice(1).map((column) => (
                                <TableCell
                                  key={column.id}
                                  align="right"
                                  sx={{ position: 'relative' }}
                                >
                                  {column.showBar && (
                                    <Box
                                      className="metrics-bar"
                                      sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: `${((shelf[column.id as keyof ComponentMetrics] as number) / (column.getBarMaxValue?.(summaries) || 1)) * 100}%`,
                                        bgcolor: 'primary.main',
                                        opacity: 0.1,
                                        transition: 'opacity 0.2s',
                                      }}
                                    />
                                  )}
                                  <Typography
                                    variant="body2"
                                    sx={{ position: 'relative' }}
                                  >
                                    {column.format(
                                      shelf[
                                        column.id as keyof ComponentMetrics
                                      ],
                                    )}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IXSummaryTable;
