import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { IShelfSearchItem } from '../../types/post-shelf-item-search.type';
import { LoadMoreButton } from './styles';

const ItemSliderContainer = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const ItemSliderWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 16px;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }
`;

const ItemContainer = styled.a`
  text-decoration: none;
  color: inherit;
  min-width: 120px;
  margin-right: 16px;
  text-align: center;
`;

const ItemImage = styled.img`
  width: 100px;
  object-fit: cover;
  margin-bottom: 8px;
  border-radius: 4px;
`;

const ItemTitle = styled(Typography)`
  width: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 10px;
  margin-bottom: 4px;
`;

interface ItemSliderProps {
  items: IShelfSearchItem[];
  visibleItems: IShelfSearchItem[];
  onLoadMore: () => void;
}

const ItemSlider: React.FC<ItemSliderProps> = ({
  items,
  visibleItems,
  onLoadMore,
}) => {
  return (
    <ItemSliderContainer>
      <ItemSliderWrapper>
        {visibleItems.map((item: IShelfSearchItem) => (
          <ItemContainer key={item.aid} href={item.item_url} target="_blank">
            <ItemImage
              src={item.image_url}
              alt={item.item_title}
              loading="lazy"
            />
            <ItemTitle variant="body2">{item.item_title}</ItemTitle>
            <Typography variant="caption">
              ランク: {item.pv_count_7d_rank}
            </Typography>
          </ItemContainer>
        ))}
      </ItemSliderWrapper>
      {items.length > visibleItems.length && (
        <LoadMoreButton onClick={onLoadMore} variant="outlined">
          もっと見る
        </LoadMoreButton>
      )}
    </ItemSliderContainer>
  );
};

export default ItemSlider;
