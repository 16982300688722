import React from 'react';
import styled from 'styled-components';

const ReviewResultContainer = styled.div<{ score?: number }>`
  font-size: 12px;
  ${(props) =>
    props.score &&
    `color: ${
      props.score >= 7 ? 'green' : props.score >= 5 ? 'orange' : 'red'
    }`};
`;

interface ReviewResultProps {
  score?: number;
  reason?: string;
}

const ReviewResult: React.FC<ReviewResultProps> = ({ score, reason }) => {
  if (!score) return null;

  return (
    <ReviewResultContainer score={score}>
      <div>レビュースコア: {score}</div>
      <div>理由: {reason || 'なし'}</div>
    </ReviewResultContainer>
  );
};

export default ReviewResult;
