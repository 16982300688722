import { DeviceEnum } from '../../enums/device.enum';
import { GenderEnum } from '../../enums/gender.enum';
import type { IConfig } from './config.interface';

export type ConfigProps = {
  env?: string;
  deviceType?: DeviceEnum;
  gender?: GenderEnum;
  featureFlags?: Record<string, string>;
};

export class Config implements IConfig {
  env: string;
  device: DeviceEnum;
  gender: GenderEnum;
  featureFlags: Record<string, string> = {};

  async init(): Promise<void> {
    this.env = await this.getEnv();
    this.device = await this.getDevice();
    this.gender = await this.getGender();
    this.featureFlags = await this.getFeatureFlags();
  }

  async getEnv(): Promise<string> {
    return 'staging';
  }

  async getDevice(): Promise<DeviceEnum> {
    return DeviceEnum.mobile;
  }

  async getGender(): Promise<GenderEnum> {
    return GenderEnum.women;
  }

  async getFeatureFlags(): Promise<Record<string, string>> {
    return {};
  }

  getIsMobile(): boolean {
    return [DeviceEnum.mobile, DeviceEnum.app].includes(this.device);
  }
}
