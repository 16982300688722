export enum PageTypeEnum {
  Top = 'Top',
  Top2 = 'Top2',
  Pdp = 'Pdp',
  Brand = 'Brand',
  Mypage = 'Mypage',
  Favorite = 'Favorite',
  OrderHistory = 'OrderHistory',
  OrderConfirm = 'OrderConfirm',
  OrderComplete = 'OrderComplete',
  MemberComplete = 'MemberComplete',
  Coordinate = 'Coordinate',
  CartShow = 'CartShow',
  Sale = 'Sale',
  Info = 'Info',
  News = 'News',
  Footer = 'Footer',
  Shipping = 'Shipping',
  List = 'List',
  Empty = 'Empty',
}
