import {
  IPostShelfItemSearchReq,
  IPostShelfItemSearchRes,
} from '../../types/post-shelf-item-search.type';
import api from '../api';

export const postShelfItemsSearch = async (
  dto: IPostShelfItemSearchReq,
): Promise<IPostShelfItemSearchRes> => {
  const res = await api.post('/shelf/shelf_items/search', dto);
  return res.data;
};
