/* eslint-disable @typescript-eslint/no-unused-vars */
import { ShelfUiTypeEnum } from '../../../enums/shelf-ui-type.enum';
import type { IItemOption } from '../../../types/item-option.type';
import type { IShelfOption } from '../../../types/shelf-option.type';
import type { IShelf } from '../../../types/shelf.type';
import type { ITaglineOption } from '../../../types/tagline-option.type';
import { PreprocessServiceBase } from '../preprocess.base.service';

export class UrbanPreprocessService extends PreprocessServiceBase {
  private fontFamily =
    '"Hiragino Kaku Gothic ProN","游ゴシック Medium",YuGothic,YuGothicM,メイリオ,Meiryo,sans-serif';

  async getShelfOverride(shelf: IShelf): Promise<IShelf> {
    const uiType: ShelfUiTypeEnum = ShelfUiTypeEnum.slider;
    return { uiType };
  }

  async getOptions(shelf: IShelf): Promise<{
    shelfOption: IShelfOption;
    taglineOption: ITaglineOption;
    itemOption: IItemOption;
  }> {
    shelf.shelfOption.fontFamily = this.fontFamily;

    const {
      shelfStyle,
      tagline1Style,
      tagline2Style,
      itemsStyle,
      itemScrollStyle,
      titleStyle,
      brandStyle,
      priceStyle,
    } = await super.getBaseStyles(shelf);

    const shelfOption: IShelfOption = {
      shelfStyle: {
        ...shelfStyle,
        marginBottom: '30px',
      },
    };

    const taglineOption: ITaglineOption = {
      taglinesStyle: {
        textAlign: 'center',
        marginBottom: '40px',
      },
      tagline1Style: {
        ...tagline1Style,
        fontSize: '14px',
      },
      tagline2Style: {
        ...tagline2Style,
        fontSize: '17px',
        fontWeight: 'bold',
      },
    };

    const itemOption: IItemOption = {
      widthPx: 150,
      imageRatio: 180.0 / 150.0,
      brandLineClamp: 1,
      titleLineClamp: 2,
      itemsStyle: {
        ...itemsStyle,
      },
      itemScrollStyle: {
        ...itemScrollStyle,
        marginLeft: '18px',
        marginRight: '18px',
      },
      brandStyle: {
        ...brandStyle,
        fontSize: '10px',
        color: '#747474',
        marginBottom: '2px',
      },
      titleStyle: {
        ...titleStyle,
        fontSize: '10px',
        color: '#000',
        paddingRight: '3px',
      },
      priceStyle: {
        ...priceStyle,
        fontSize: '10px',
      },
    };

    return { shelfOption, taglineOption, itemOption };
  }
}
