// BrandCard.tsx

/* eslint-disable @typescript-eslint/no-explicit-any */
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CircularProgress, IconButton, MenuItem, Select } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';
import { ShelfContainer } from '../../insightx-utils/services/container/shelf.container';
import { IShelf } from '../../insightx-utils/types/shelf.type';
import { useClient } from '../../store/client.store';
import { IBrandUuN1 } from '../../types/brand-uu-n1.type';
import { IBrandUu } from '../../types/brand-uu.type';
import { IShelfConfig } from '../../types/shelf-config.type';
import Shelf from '../common-shelf/Shelf';
import ShelfN1User from '../n1-user/ShelfN1User';
import {
  BrandCardStyled,
  BrandTitle,
  SelectContainer,
} from './StyledComponents';
import UserBehaviorLogs from './UserBehaviorLogs';

interface BrandCardProps {
  brandUu: IBrandUu;
  shelfConfig: IShelfConfig;
  logs: IBrandUuN1[];
}

const BrandCardGrid = styled.div`
  display: flex;
  gap: 20px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  /* スクロールバーを非表示にするスタイル */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

const SmartphoneFrame = styled.div`
  width: 500px;
  height: 1200px;
  border: 16px solid #ddd;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  overflow-y: auto;
  position: relative;
  background: white;

  /* スクロールバーを非表示にするスタイル */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    background: #9e9e9e;
    border-radius: 10px;
  }

  &::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: -48px;
    left: 50%;
    transform: translateX(-50%);
    background: #9e9e9e;
    border-radius: 50%;
  }
`;

const ShelvesContainer = styled.div`
  height: 100%;
  overflow-y: auto;

  /* スクロールバーを非表示にするスタイル */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

export const NextButton = styled.button`
  padding: 5px 10px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const BrandCard: React.FC<BrandCardProps> = ({
  brandUu,
  shelfConfig,
  logs,
}) => {
  const [selectedUuId, setSelectedUuId] = useState<string>(brandUu.uuIds[0]);
  const [shelves, setShelves] = useState<IShelf[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { client: clientState } = useClient();

  // フィルタリングされたログ（選択されたuuId）
  const filteredLogs = useMemo(() => {
    return logs
      .filter((log) => log.uuId === selectedUuId)
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) return 1;
        if (a.createdAt < b.createdAt) return -1;
        return 0;
      });
  }, [logs, selectedUuId]);

  const pageViewPdpLogs = useMemo(() => {
    return filteredLogs
      .filter(
        (log) => log.eventType === 'PageView' && log.urlPageType === 'pdp',
      )
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;
        return 0;
      });
  }, [filteredLogs]);

  const createShelfFromLogs = (logs: IBrandUuN1[], uuId: string): IShelf => {
    return {
      shelfId: `shelf-${uuId}`,
      shelfType: ShelfTypeEnum.history,
      items: logs.map((log) => ({
        aid: log.aid,
        title: log.itemTitle,
        brand: log.brandName,
        url: log.url,
        category: log.category,
        imageUrl: log.imageUrl,
        imageUrls: [log.imageUrl],
        createdAt: log.createdAt || '',
      })),
      createdAt: '', // 必要に応じて設定
    };
  };

  // IShelfオブジェクトを作成
  const shelfFromLogs = useMemo(
    () => createShelfFromLogs(pageViewPdpLogs, selectedUuId),
    [pageViewPdpLogs, selectedUuId],
  );

  useEffect(() => {
    const fetchShelves = async () => {
      setIsLoading(true);
      try {
        const container = new ShelfContainer(
          clientState.selectedClient.name,
          clientState.selectedClient.clientId,
          shelfConfig,
          selectedUuId,
          undefined,
        );
        await container.init();
        await container.shelfService.bootstrap();
        setShelves(container.shelfService.shelves);
      } catch (error) {
        console.error('Error fetching shelves:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchShelves();
  }, [selectedUuId, shelfConfig]);

  const handleCopyUuId = () => {
    navigator.clipboard.writeText(selectedUuId).catch((err) => {
      console.error('Failed to copy UuId: ', err);
    });
  };

  const handleNextUuId = () => {
    const currentIndex = brandUu.uuIds.indexOf(selectedUuId);
    const nextIndex = (currentIndex + 1) % brandUu.uuIds.length;
    setSelectedUuId(brandUu.uuIds[nextIndex]);
  };

  return (
    <BrandCardStyled>
      <BrandTitle>{brandUu.brandName}を見たユーザー</BrandTitle>
      <BrandCardGrid>
        <LeftColumn>
          <SelectContainer style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={handleCopyUuId}
              size="small"
              style={{ marginRight: '8px' }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
            <Select
              value={selectedUuId}
              onChange={(e) => setSelectedUuId(e.target.value)}
              style={{ flex: 1 }}
              fullWidth
            >
              {brandUu.uuIds.map((uuId: string) => (
                <MenuItem key={uuId} value={uuId}>
                  {uuId}
                </MenuItem>
              ))}
            </Select>
            <NextButton onClick={handleNextUuId}>次</NextButton>
          </SelectContainer>
          {pageViewPdpLogs.length > 0 && <ShelfN1User shelf={shelfFromLogs} />}
          <UserBehaviorLogs logs={filteredLogs} />
        </LeftColumn>
        <RightColumn>
          <SmartphoneFrame>
            <ShelvesContainer>
              {isLoading ? (
                <CircularProgress />
              ) : (
                shelves.map((shelf, index) => (
                  <Shelf key={index} shelf={shelf} />
                ))
              )}
            </ShelvesContainer>
          </SmartphoneFrame>
        </RightColumn>
      </BrandCardGrid>
    </BrandCardStyled>
  );
};

export default BrandCard;
