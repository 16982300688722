import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { SimilarShelvesState } from './ShelfBuilder';

const SimilarShelvesContainer = styled.div`
  margin-top: 16px;
`;

const SimilarShelfItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
`;

interface SimilarShelvesProps {
  similarShelves: SimilarShelvesState;
}

const SimilarShelves: React.FC<SimilarShelvesProps> = ({ similarShelves }) => {
  if (!similarShelves || similarShelves.length === 0) return null;

  return (
    <SimilarShelvesContainer>
      <Typography variant="h6" gutterBottom>
        類似シェルフ
      </Typography>
      {similarShelves.map((similar, index) => (
        <SimilarShelfItem key={index}>
          <Typography variant="body2">{similar.text}</Typography>
          <Typography variant="body2">
            類似度: {(similar.cossim_score * 100).toFixed(2)}%
          </Typography>
        </SimilarShelfItem>
      ))}
    </SimilarShelvesContainer>
  );
};

export default SimilarShelves;
