import { Box } from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import React from 'react';
import { ComponentMetrics } from '../../services/component-service';
import { getPageTypeLabel } from '../../utils/page-type-utils';

interface CustomPageTabsProps {
  pageTypes: string[];
  selectedPageType: string;
  onChange: (event: React.SyntheticEvent | null, value: string) => void;
  metrics: ComponentMetrics[];
  startDate: string;
  endDate: string;
}

const CustomPageTabs: React.FC<CustomPageTabsProps> = ({
  pageTypes,
  selectedPageType,
  onChange,
  metrics,
  startDate,
  endDate,
}) => {
  // 日数の差分を計算
  const daysDiff = differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;

  // Calculate revenue for each page type
  const pageRevenues = pageTypes.reduce(
    (acc, pageType) => {
      const pageMetrics = metrics.filter(
        (m) => m.url_page_type === pageType && m.component?.startsWith('IX'),
      );
      const totalCV = pageMetrics.reduce((sum, m) => sum + (m.cv || 0), 0);
      const dailyRevenue = (totalCV * 10000) / daysDiff;
      const monthlyRevenue = dailyRevenue * 30;
      return { ...acc, [pageType]: monthlyRevenue };
    },
    {} as Record<string, number>,
  );

  // Sort page types by IX revenue
  const sortedPageTypes = [...pageTypes].sort(
    (a, b) => pageRevenues[b] - pageRevenues[a],
  );

  // Check if page type has IX components
  const hasIX = (pageType: string): boolean => {
    return metrics.some(
      (m) => m.url_page_type === pageType && m.component?.startsWith('IX'),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1px',
        bgcolor: 'grey.100',
        p: '1px',
        borderRadius: 2,
        overflow: 'auto',
        mb: 2,
      }}
    >
      {sortedPageTypes.map((pageType) => (
        <Box
          key={pageType}
          onClick={() => onChange(null, pageType)}
          sx={{
            minWidth: 160,
            px: 3,
            py: 2,
            cursor: 'pointer',
            bgcolor: selectedPageType === pageType ? 'primary.main' : 'white',
            color: selectedPageType === pageType ? 'white' : 'text.primary',
            transition: 'all 0.2s ease',
            borderRadius: 1.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            position: 'relative',
            fontWeight: selectedPageType === pageType ? 600 : 400,
            '&:hover': {
              bgcolor:
                selectedPageType === pageType ? 'primary.dark' : 'grey.50',
            },
            ...(hasIX(pageType) && {
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  'linear-gradient(45deg, transparent 97%, primary.light 97%)',
                opacity: selectedPageType === pageType ? 0.1 : 0.05,
                pointerEvents: 'none',
              },
            }),
          }}
        >
          {hasIX(pageType) && (
            <Box
              component="img"
              src="/favicon.png"
              alt="InsightX"
              sx={{
                mr: 1,
                width: 16,
                height: 16,
                filter:
                  selectedPageType === pageType ? 'brightness(2)' : 'none',
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {getPageTypeLabel(pageType)}
            {hasIX(pageType) && (
              <Box
                component="span"
                sx={{
                  fontSize: '0.75rem',
                  opacity: 0.8,
                  mt: 0.5,
                }}
              >
                ¥{Math.floor(pageRevenues[pageType]).toLocaleString()}
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CustomPageTabs;
