import {
  IPostItemsSearchReq,
  IPostItemsSearchRes,
} from '../../types/post-items-search.type';
import api from '../api';

export const postItemsSearch = async (
  dto: IPostItemsSearchReq,
): Promise<IPostItemsSearchRes> => {
  const res = await api.post(`/recommends/items/search`, dto);
  return res.data;
};
