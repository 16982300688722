import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import { ChevronDown, ChevronRight, Info } from 'lucide-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ComponentMetrics } from '../../services/component-service';

interface MetricsTableProps {
  metrics: ComponentMetrics[];
  startDate: string;
  endDate: string;
}

// 値の安全な取得のためのヘルパー関数
const safeNumber = (value: number | null | undefined): number => {
  if (value === null || value === undefined || isNaN(value)) {
    return 0;
  }
  return value;
};

const safeDivide = (numerator: number, denominator: number): number => {
  if (denominator === 0 || !denominator) return 0;
  const result = numerator / denominator;
  return isFinite(result) ? result : 0;
};

// Column定義の型を定義
type ColumnDefinition = {
  id: string;
  label: string;
  tooltip: string;
  format: (value: any) => string;
  getValue:
    | ((metric: any) => number)
    | ((metric: any, startDate: string, endDate: string) => number);
};

// Column definitions with tooltips
const columns: ColumnDefinition[] = [
  {
    id: 'shown',
    label: '表示数',
    tooltip: '期間中にユーザーの画面に表示された回数',
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.shown),
  },
  {
    id: 'click',
    label: 'クリック数',
    tooltip: 'ユーザーがコンポーネントをクリックした回数',
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.click),
  },
  {
    id: 'ctr',
    label: 'クリック率',
    tooltip: '表示数に対するクリック数の割合 (クリック数÷表示数)',
    format: (value: any) => `${(safeNumber(value) * 100).toFixed(1)}%`,
    getValue: (metric: any) =>
      safeDivide(safeNumber(metric.click), safeNumber(metric.shown)),
  },
  {
    id: 'cv',
    label: 'CV数',
    tooltip: '商品購入などのコンバージョンが発生した数',
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.cv),
  },
  {
    id: 'cvr',
    label: 'CVR',
    tooltip: 'クリック数に対するコンバージョン数の割合 (CV数÷クリック数)',
    format: (value: any) => `${(safeNumber(value) * 100).toFixed(1)}%`,
    getValue: (metric: any) =>
      safeDivide(safeNumber(metric.cv), safeNumber(metric.click)),
  },
  {
    id: 'revenue',
    label: '月間換算売上',
    tooltip: '1CVあたり10,000円で計算した月間の想定売上額（期間の日数で調整）',
    format: (value: any) =>
      `¥${Math.round(safeNumber(value)).toLocaleString()}`,
    getValue: (metric: any, startDate: string, endDate: string) => {
      const daysDiff =
        differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;
      const cv = safeNumber(metric.cv);
      // 日次売上を計算してから月間に換算
      const dailyRevenue = (cv * 10000) / daysDiff;
      const monthlyRevenue = dailyRevenue * 30;
      return monthlyRevenue;
    },
  },
];

const MetricsTable: React.FC<MetricsTableProps> = ({
  metrics = [],
  startDate,
  endDate,
}) => {
  const [expandedIX, setExpandedIX] = useState(false);
  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  const { ixMetrics, otherMetrics, ixSummary, maxValues } = useMemo(() => {
    // Ensure metrics is an array
    const validMetricsArray = Array.isArray(metrics) ? metrics : [];

    // Filter out IX components that are not shelves
    const validMetrics = validMetricsArray.filter(
      (m) => m && !(m.component?.startsWith('IX') && m.type === 'component'),
    );

    // Separate IX and non-IX metrics
    const ixMetrics = validMetrics
      .filter((m) => m.type === 'shelf')
      .sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown));

    const otherMetrics = validMetrics
      .filter((m) => m.type === 'component')
      .sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown));

    // Calculate IX summary
    const ixSummary = {
      shown: ixMetrics.reduce(
        (max, m) => Math.max(max, safeNumber(m.shown)),
        0,
      ),
      click: ixMetrics.reduce((sum, m) => sum + safeNumber(m.click), 0),
      cv: ixMetrics.reduce((sum, m) => sum + safeNumber(m.cv), 0),
      get ctr() {
        return safeDivide(this.click, this.shown);
      },
      get cvr() {
        return safeDivide(this.cv, this.click);
      },
      get revenue() {
        const daysDiff =
          differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;
        // 日次売上を計算してから月間に換算
        const dailyRevenue = (this.cv * 10000) / daysDiff;
        const monthlyRevenue = dailyRevenue * 30;
        return monthlyRevenue;
      },
    };

    // Calculate max values for bar backgrounds
    const maxValues = columns.reduce(
      (acc, column) => {
        const values = validMetrics.map((m) => {
          if (column.id === 'revenue') {
            return (
              column.getValue as (
                metric: any,
                startDate: string,
                endDate: string,
              ) => number
            )(m, startDate, endDate);
          }
          return (column.getValue as (metric: any) => number)(m);
        });
        acc[column.id] = Math.max(0, ...values);
        return acc;
      },
      {} as Record<string, number>,
    );

    return { ixMetrics, otherMetrics, ixSummary, maxValues };
  }, [metrics, startDate, endDate]);

  const renderCell = (metric: any, columnId: string, maxValue: number) => {
    const column = columns.find((col) => col.id === columnId)!;
    const value =
      column.id === 'revenue'
        ? (
            column.getValue as (
              metric: any,
              startDate: string,
              endDate: string,
            ) => number
          )(metric, startDate, endDate)
        : (column.getValue as (metric: any) => number)(metric);
    const displayValue = column.format(value);

    return (
      <TableCell
        key={columnId}
        sx={{
          position: 'relative',
          textAlign: 'right',
          py: 2,
          '&:hover': {
            '& .metrics-bar': {
              opacity: 0.15,
            },
          },
        }}
      >
        <Box
          className="metrics-bar"
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: `${maxValue > 0 ? (safeNumber(value) / maxValue) * 100 : 0}%`,
            bgcolor: 'primary.main',
            opacity: 0.1,
            transition: 'opacity 0.2s',
          }}
        />
        <Typography
          variant="body2"
          sx={{
            position: 'relative',
            fontWeight: 'medium',
            color: 'text.primary',
          }}
        >
          {displayValue}
        </Typography>
      </TableCell>
    );
  };

  if (!metrics || metrics.length === 0) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
                データがありません
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 1000,
        borderRadius: 2,
        boxShadow: (theme) => theme.shadows[2],
      }}
    >
      <Table stickyHeader size="small">
        <TableHead
          ref={headerRef}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 3,
            bgcolor: 'background.paper',
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                position: 'sticky',
                left: 0,
                zIndex: 4,
                bgcolor: 'background.paper',
                borderRight: '1px solid',
                borderRightColor: 'divider',
                boxShadow: '2px 0 4px rgba(0,0,0,0.1)',
                minWidth: 200,
              }}
            >
              コンポーネント
            </TableCell>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align="right"
                sx={{
                  whiteSpace: 'nowrap',
                  bgcolor: 'background.paper',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 0.5,
                  }}
                >
                  {column.label}
                  <Tooltip title={column.tooltip} arrow placement="top">
                    <Info size={16} color="#666" />
                  </Tooltip>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* IX Summary Row */}
          {ixMetrics.length > 0 && (
            <>
              <TableRow
                sx={{
                  position: 'sticky',
                  top: headerHeight,
                  zIndex: 2,
                  cursor: 'pointer',
                  bgcolor: 'white',
                }}
                onClick={() => setExpandedIX(!expandedIX)}
              >
                <TableCell
                  sx={{
                    position: 'sticky',
                    left: 0,
                    bgcolor: 'primary.lighter',
                    zIndex: 3,
                    borderRight: '1px solid',
                    borderRightColor: 'divider',
                    boxShadow: '2px 0 4px rgba(0,0,0,0.1)',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton size="small">
                      {expandedIX ? (
                        <ChevronDown size={20} />
                      ) : (
                        <ChevronRight size={20} />
                      )}
                    </IconButton>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: 'bold', color: 'primary.main' }}
                    >
                      InsightXコンポーネント ({ixMetrics.length}種類)
                    </Typography>
                  </Box>
                </TableCell>
                {columns.map((column) =>
                  renderCell(ixSummary, column.id, maxValues[column.id]),
                )}
              </TableRow>

              <TableRow>
                <TableCell colSpan={columns.length + 1} sx={{ p: 0 }}>
                  <Collapse in={expandedIX}>
                    <Box sx={{ bgcolor: 'grey.50' }}>
                      {ixMetrics.map((metric) => (
                        <TableRow
                          key={metric.component}
                          sx={{ '&:hover': { bgcolor: 'action.hover' } }}
                        >
                          <TableCell
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 2,
                              bgcolor: 'grey.50',
                              borderRight: '1px solid',
                              borderRightColor: 'divider',
                              boxShadow: '2px 0 4px rgba(0,0,0,0.1)',
                              pl: 6,
                            }}
                          >
                            <Typography variant="body2">
                              {metric.component}
                            </Typography>
                          </TableCell>
                          {columns.map((column) =>
                            renderCell(metric, column.id, maxValues[column.id]),
                          )}
                        </TableRow>
                      ))}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          )}

          {/* Other Component Rows */}
          {otherMetrics.map((metric) => (
            <TableRow
              key={metric.component}
              sx={{ '&:hover': { bgcolor: 'action.hover' } }}
            >
              <TableCell
                sx={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 1,
                  bgcolor: 'background.paper',
                  borderRight: '1px solid',
                  borderRightColor: 'divider',
                  boxShadow: '2px 0 4px rgba(0,0,0,0.1)',
                }}
              >
                <Typography variant="body2">{metric.component}</Typography>
              </TableCell>
              {columns.map((column) =>
                renderCell(metric, column.id, maxValues[column.id]),
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MetricsTable;
