import { IUser } from '../types/user.type';
import api from './api';

export const getToken = async (): Promise<string | null> => {
  const user = localStorage.getItem('ix_dashboard_user');
  if (user) {
    const parsedUser: IUser = JSON.parse(user);
    return parsedUser.token;
  }
  return null;
};

export const loginWithGoogle = async (token: string) => {
  const response = await api.post('/auth/google', {
    token,
  });
  return {
    user: response.data.user,
    token: response.data.token,
  };
};

export const loginWithCredentials = async (
  username: string,
  password: string,
) => {
  const response = await api.post('/auth/login', {
    username,
    password,
  });
  return {
    user: response.data.user,
    token: response.data.token,
  };
};
