import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IClient } from '../types/client.type';

const clientsAtom = atom<IClient[]>([]);
const selectedClientIdAtom = atomWithStorage<string | null>(
  'ix_dashboard_selected_client',
  null,
);

export const clientAtom = atom(
  (get) => ({
    selectedClient:
      get(clientsAtom).find(
        (client) => client.clientId === get(selectedClientIdAtom),
      ) || null,
    clients: get(clientsAtom),
    isLoading: get(clientsAtom).length === 0,
  }),
  (
    get,
    set,
    action:
      | { type: 'setClients'; payload: IClient[] }
      | { type: 'setSelectedClient'; payload: string },
  ) => {
    switch (action.type) {
      case 'setClients':
        set(clientsAtom, action.payload);
        if (!get(selectedClientIdAtom) && action.payload.length > 0) {
          set(selectedClientIdAtom, action.payload[0].clientId);
        }
        break;
      case 'setSelectedClient':
        set(selectedClientIdAtom, action.payload);
        break;
    }
  },
);

export const useClient = () => {
  const [client, dispatch] = useAtom(clientAtom);
  const navigate = useNavigate();
  const location = useLocation();

  const setClients = useCallback(
    (clients: IClient[]) => {
      dispatch({ type: 'setClients', payload: clients });
    },
    [dispatch],
  );

  const setSelectedClient = useCallback(
    (clientId: string) => {
      dispatch({ type: 'setSelectedClient', payload: clientId });

      // Update URL parameter
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('clientId', clientId);
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [dispatch, navigate, location],
  );

  return { client, setClients, setSelectedClient };
};
