import type { IStyle } from '../types/style.type';

export const StyleUtil = {
  camelToKebab(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
  },

  processValue(value: any): string {
    if (typeof value === 'number' && value !== 0) {
      return `${value}px`;
    }
    return String(value);
  },

  styleToCSS(style: IStyle, indent: string = ''): string {
    let css = '';

    for (const [key, value] of Object.entries(style)) {
      if (key.startsWith('@media')) {
        // メディアクエリの処理
        css += `\n${indent}${key} {\n${StyleUtil.styleToCSS(value as IStyle, indent + '  ')}\n${indent}}`;
      } else if (key.startsWith(':')) {
        // 擬似クラス・擬似要素の処理
        css += `\n${indent}${key} {\n${StyleUtil.styleToCSS(value as IStyle, indent + '  ')}\n${indent}}`;
      } else if (typeof value === 'object' && value !== null) {
        // ネストされたスタイルの処理（この場合は無視）
        console.warn(`Nested style object found for key "${key}". Ignoring.`);
      } else {
        // 通常のCSSプロパティの処理
        const cssKey = StyleUtil.camelToKebab(key);
        const cssValue = StyleUtil.processValue(value);
        css += `\n${indent}${cssKey}: ${cssValue};`;
      }
    }

    return css.trim();
  },

  convertStyleToCSS(style: IStyle): string {
    return StyleUtil.styleToCSS(style);
  },
};
