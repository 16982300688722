import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/auth.store';
import { useClient } from '../store/client.store';

export const ClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { client, setClients } = useClient();
  const { auth } = useAuth();
  const { user } = auth;
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated && user && user.clients) {
      setClients(user.clients);
    }
  }, [user, setClients, auth.isAuthenticated]);

  if (auth.isAuthenticated && client.isLoading) {
    navigate('/login');
    return <>{children}</>;
  }

  return <>{children}</>;
};
