// ShelfPreviewerPage.tsx

import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';
import { getBrandUuN1 } from '../../services/shelf-previewer/get-brand-uu-n1';
import { getBrandUus } from '../../services/shelf-previewer/get-brand-uus';
import { useClient } from '../../store/client.store';
import { IBrandUuN1 } from '../../types/brand-uu-n1.type'; // 追加
import { IBrandUu } from '../../types/brand-uu.type';
import { IShelfConfig } from '../../types/shelf-config.type';
import ShelfConfigBuilder from '../shelf-config-builder/ShelfConfigBuilder';
import BrandCard from './BrandCard';
import { GlobalStyle, PageContainer } from './StyledComponents';

const BrandCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const LoadMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const ShelfPreviewerPage: React.FC = () => {
  const [brandUus, setBrandUus] = useState<IBrandUu[]>([]);
  const [brandUuN1, setBrandUuN1] = useState<IBrandUuN1[]>([]); // 新しい状態
  const [visibleBrandUus, setVisibleBrandUus] = useState<IBrandUu[]>([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const { client: clientState } = useClient();
  const [shelfConfig, setShelfConfig] = useState<IShelfConfig>({
    historyFilteredItemNum: 100,
    shelfOrders: [
      {
        shelfType: ShelfTypeEnum.foryou,
        shelfNum: 1,
        maxItems: 50,
      },
      {
        shelfType: ShelfTypeEnum.perspective,
        shelfNum: 5,
        maxItems: 50,
        shelfOrders: [
          {
            shelfType: ShelfTypeEnum.viewedBrand,
            shelfNum: 2,
            maxItems: 50,
            filteringItemNum: 10,
            targetTopN: 3,
          },
          {
            shelfType: ShelfTypeEnum.favoriteTrend,
            shelfNum: 1,
            maxItems: 50,
            filteringItemNum: 10,
            targetTopN: 3,
          },
          {
            shelfType: ShelfTypeEnum.popularTrend,
            shelfNum: 1,
            maxItems: 50,
            filteringItemNum: 10,
            targetTopN: 3,
          },
          {
            shelfType: ShelfTypeEnum.newPopular,
            shelfNum: 1,
            maxItems: 50,
            filteringItemNum: 10,
            targetTopN: 3,
          },
          {
            shelfType: ShelfTypeEnum.search,
            shelfNum: 10,
            maxItems: 50,
            filteringItemNum: 10,
            targetTopN: 2,
          },
        ],
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBrandUus = async () => {
      setIsLoading(true);
      const res = await getBrandUus();
      setBrandUus(res);

      const uuIds: string[] = [];
      res.forEach((brandUu) => {
        uuIds.push(...brandUu.uuIds);
      });
      const n1 = await getBrandUuN1(uuIds);
      setBrandUuN1(n1); // n1データを状態に設定

      setVisibleBrandUus(res.slice(0, visibleCount));
      setIsLoading(false);
    };
    fetchBrandUus();
  }, [clientState.selectedClient]);

  useEffect(() => {
    setVisibleBrandUus(brandUus.slice(0, visibleCount));
  }, [visibleCount, brandUus]);

  const handleConfigChange = (newConfig: IShelfConfig) => {
    setShelfConfig(newConfig);
  };

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  return (
    <PageContainer>
      <GlobalStyle />
      <ShelfConfigBuilder
        shelfConfig={shelfConfig}
        onConfigChange={handleConfigChange}
        baseType="user-based"
      />
      {isLoading && <CircularProgress />}
      <BrandCardContainer>
        {visibleBrandUus.map((brandUu) => (
          <BrandCard
            key={brandUu.brandName}
            brandUu={brandUu}
            shelfConfig={shelfConfig}
            logs={brandUuN1.filter((log) => brandUu.uuIds.includes(log.uuId))} // フィルタリングしたログを渡す
          />
        ))}
      </BrandCardContainer>
      {visibleCount < brandUus.length && (
        <LoadMoreButton onClick={handleLoadMore}>もっと見る</LoadMoreButton>
      )}
    </PageContainer>
  );
};

export default ShelfPreviewerPage;
