import React from 'react';
import { IBrandItem } from '../../types/brand-item.type';
import BrandCard from './BrandCard';
import styled from '@emotion/styled';
import { IShelfConfig } from '../../types/shelf-config.type';

const BrandGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

interface BrandGridProps {
  brandItems: IBrandItem[];
  shelfConfig: IShelfConfig;
}

const BrandGrid: React.FC<BrandGridProps> = ({ brandItems, shelfConfig }) => {
  return (
    <BrandGridStyled>
      {brandItems.map((brandItem) => (
        <BrandCard
          key={brandItem.brandName}
          brandItem={brandItem}
          shelfConfig={shelfConfig}
        />
      ))}
    </BrandGridStyled>
  );
};

export default BrandGrid;
