import styled, { createGlobalStyle } from 'styled-components';
import {
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

export const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px;
`;

export const BrandGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const BrandCardStyled = styled.div`
  width: 100%;
  min-width: 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const BrandTitle = styled.h2`
  color: #ff6600;
  margin-bottom: 10px;
`;

export const TaglinesContainer = styled.div`
  margin-bottom: 5px;
`;

export const Tagline1 = styled.div`
  font-size: 12px;
  color: #828282;
  margin-bottom: 2px;
`;

export const Tagline2 = styled.div`
  font-size: 16px;
  color: #000;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`;

export const LoadMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px 0;
  z-index: 1000;
  display: none;
`;

// ShelfConfigBuilder用のスタイル
export const BuilderContainer = styled.div`
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #fefefe;
`;

export const Field = styled.div`
  margin-bottom: 15px;
`;

export const FormContainer = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const FormColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;

export const Button = styled.button`
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const RemoveButton = styled.button`
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  background-color: #f5f5f5;

  &.Mui-expanded {
    min-height: 48px;
  }

  .MuiAccordionSummary-content {
    margin: 12px 0;
  }

  h3 {
    margin: 0;
    font-size: 1.1rem;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 16px;
`;

export const JsonDisplay = styled.pre`
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  overflow-x: auto;
  font-size: 0.9rem;
  line-height: 1.4;
`;
