import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useState } from 'react';
import {
  ComponentMetrics,
  componentService,
} from '../../services/component-service';
import { useClient } from '../../store/client.store';
import CustomPageTabs from './CustomPageTabs';
import DateRangePicker from './DateRangePicker';
import IXSummaryTable from './IXSummaryTable';
import MetricsTable from './MetricsTable';
import ShelfPerformance from './shelf-performance/ShelfPerformance';

const MetricsViewer: React.FC = () => {
  const theme = useTheme();
  const { client: clientState } = useClient();
  const [startDate, setStartDate] = useState(
    format(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [selectedPageType, setSelectedPageType] = useState('');
  const [selectedShelfPageType, setSelectedShelfPageType] = useState('all');

  const { data, isLoading, error } = useQuery({
    queryKey: [
      'componentStats',
      startDate,
      endDate,
      clientState.selectedClient,
    ],
    queryFn: () =>
      componentService.getComponentStats({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <Typography color="error">
          データの読み込み中にエラーが発生しました
        </Typography>
      </Box>
    );
  }

  // Group metrics by url_page_type and check for IX components
  const metricsByPageType: Record<string, ComponentMetrics[]> = {};
  const hasIXComponents: Record<string, boolean> = {};

  data?.stats.forEach((metric) => {
    const pageType = metric.url_page_type;
    if (!metricsByPageType[pageType]) {
      metricsByPageType[pageType] = [];
    }
    metricsByPageType[pageType].push(metric);

    if (metric.component.startsWith('IX')) {
      hasIXComponents[pageType] = true;
    }
  });

  const desiredPageTypeOrder = ['top', 'pdp', 'list', 'brand', 'other'];
  const pageTypes = Object.keys(metricsByPageType).sort(
    (a, b) => desiredPageTypeOrder.indexOf(a) - desiredPageTypeOrder.indexOf(b),
  );

  // Set the initial selected page type if not set
  if (!selectedPageType && pageTypes.length > 0) {
    setSelectedPageType(pageTypes[0]);
  }

  const handlePageTypeChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setSelectedPageType(newValue);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box mb={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: 'bold',
                color: theme.palette.primary.main,
              }}
            >
              コンポーネントパフォーマンス分析
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </Grid>
        </Grid>
      </Box>

      {/* InsightXコンポーネント サマリー セクション */}
      <Box
        sx={{
          mb: 6,
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 3,
            bgcolor: 'primary.main',
            color: 'white',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            InsightXコンポーネント サマリー
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5, opacity: 0.9 }}>
            ページタイプ別のInsightXコンポーネントのパフォーマンス指標
          </Typography>
        </Box>
        <Box sx={{ p: 3 }}>
          {data?.stats && (
            <IXSummaryTable
              metrics={data.stats}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </Box>
      </Box>

      {/* ページ別指標 セクション */}
      <Box
        sx={{
          mb: 6,
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 3,
            borderBottom: '1px solid',
            borderColor: 'divider',
            bgcolor: 'primary.main',
            color: 'white',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            他コンポーネントとの比較
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            各ページタイプにおけるコンポーネントのパフォーマンス詳細
          </Typography>
        </Box>

        <Box sx={{ px: 3, pt: 2 }}>
          <CustomPageTabs
            pageTypes={pageTypes}
            selectedPageType={selectedPageType}
            onChange={handlePageTypeChange}
            metrics={data.stats}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>

        <Box sx={{ p: 3 }}>
          {selectedPageType && metricsByPageType[selectedPageType] && (
            <MetricsTable
              metrics={metricsByPageType[selectedPageType]}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </Box>
      </Box>

      {/* シェルフ別パフォーマンス セクション */}
      <Box
        sx={{
          mb: 6,
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 3,
            borderBottom: '1px solid',
            borderColor: 'divider',
            bgcolor: 'primary.main',
            color: 'white',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            シェルフ別パフォーマンス
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            シェルフタイプ別の詳細なパフォーマンス指標
          </Typography>
        </Box>

        <Box sx={{ p: 3 }}>
          <ShelfPerformance
            startDate={startDate}
            endDate={endDate}
            selectedPageType={selectedShelfPageType}
            onPageTypeChange={setSelectedShelfPageType}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default MetricsViewer;
