export const IShelfStyleKind = {
  Shelf: 'shelf',
  Taglines: 'taglines',
  Tagline1: 'tagline1',
  Tagline2: 'tagline2',
  Items: 'items',
  ItemScroll: 'itemScroll',
  Item: 'item',
  Image: 'image',
  Info: 'info',
  Brand: 'brand',
  Title: 'title',
  Price: 'price',
  SalePrice: 'salePrice',
  Icons: 'icons',
  Icon: 'icon',
} as const;

export type IShelfStyleKind =
  (typeof IShelfStyleKind)[keyof typeof IShelfStyleKind];
