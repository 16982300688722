import React from 'react';
import styled from 'styled-components';
import { IBrandUuN1 } from '../../types/brand-uu-n1.type';
import { Box, Typography, Card, CardContent } from '@mui/material';

// ハッシュ関数を使用して文字列をカラーコードに変換
const stringToColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

interface UserBehaviorLogsProps {
  logs: IBrandUuN1[];
}

const LogsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  gap: 15px;
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
`;

const LogCard = styled(Card)`
  min-width: 250px;
  max-width: 300px;
  flex: 0 0 auto;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
`;

const UrlPageType = styled(Typography)<{ color: string }>`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${(props) => props.color};
  margin-bottom: 5px;
`;

const Title = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UrlText = styled(Typography)`
  font-size: 0.9rem;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CreatedAt = styled(Typography)<{ isHighlighted: boolean }>`
  font-size: 0.8rem;
  color: ${(props) => (props.isHighlighted ? '#ff6600' : '#888')};
  margin-top: 5px;
  font-weight: ${(props) => (props.isHighlighted ? 'bold' : 'normal')};
`;

const AdditionalInfo = styled(Box)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ProductImage = styled.img`
  width: 50px;
  border-radius: 5px;
`;

const BrandName = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 500;
`;

const PvCount = styled(Typography)`
  font-size: 0.8rem;
  color: #888;
`;

const UserBehaviorLogs: React.FC<UserBehaviorLogsProps> = ({ logs }) => {
  const isHighlighted = (currentDate: string, prevDate: string | undefined) => {
    if (!prevDate) return false;
    const currentTime = new Date(currentDate).getTime();
    const prevTime = new Date(prevDate).getTime();
    return currentTime - prevTime >= 30 * 60 * 1000; // 30分 = 30 * 60 * 1000 ミリ秒
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        ユーザーの閲覧履歴
      </Typography>
      <LogsContainer>
        {logs.map((log, index) => {
          const prevLog = index > 0 ? logs[index - 1] : undefined;
          const highlighted = isHighlighted(log.createdAt, prevLog?.createdAt);

          return (
            <LogCard
              key={`${log.uuId}-${index}`}
              onClick={() => window.open(log.url, '_blank')}
            >
              <CardContent>
                <UrlPageType color={stringToColor(log.urlPageType)}>
                  {log.urlPageType}
                </UrlPageType>
                <>
                  <Title>{log.title}</Title>
                  <UrlText>{log.url}</UrlText>
                </>
                <CreatedAt isHighlighted={highlighted}>
                  {new Date(log.createdAt).toLocaleString()}
                </CreatedAt>
                {log.urlPageType === 'pdp' && (
                  <AdditionalInfo>
                    {log.imageUrl && (
                      <ProductImage src={log.imageUrl} alt={log.itemTitle} />
                    )}
                    <Box>
                      <BrandName>{log.brandName}</BrandName>
                      <PvCount>PVランク: {log.pvCount7dRank}</PvCount>
                    </Box>
                  </AdditionalInfo>
                )}
              </CardContent>
            </LogCard>
          );
        })}
      </LogsContainer>
    </Box>
  );
};

export default UserBehaviorLogs;
