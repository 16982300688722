import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Info } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { shelfService } from '../../../services/shelf-service';
import CustomPageTabs from '../CustomPageTabs';
import { columns } from './constants';
import { ShelfTypeGroup } from './ShelfTypeGroup';
import { ShelfPerformanceProps } from './types';
import { aggregateMetricsByGroups, safeNumber } from './utils';

const ShelfPerformance: React.FC<ShelfPerformanceProps> = ({
  startDate,
  endDate,
  selectedPageType,
  onPageTypeChange,
}) => {
  const [expandedTypes, setExpandedTypes] = useState<Set<string>>(new Set());

  const { data, isLoading, error } = useQuery({
    queryKey: ['shelfStats', startDate, endDate],
    queryFn: () =>
      shelfService.getShelfStats({
        start_date: startDate,
        end_date: endDate,
      }),
  });

  const { pageTypes, shelfTypeGroups, maxValues } = useMemo(() => {
    if (!data) {
      return {
        pageTypes: [],
        shelfTypeGroups: new Map(),
        maxValues: Object.fromEntries(columns.map((col) => [col.id, 0])),
      };
    }

    let metrics = data.stats;

    // 'all'の場合は集計を行う
    if (selectedPageType === 'all') {
      metrics = aggregateMetricsByGroups(data.stats, startDate, endDate);
    } else {
      metrics = data.stats.filter((m) => m.url_page_type === selectedPageType);
    }

    // ページタイプの一覧を取得（重複を除去）
    const types = [
      'all',
      ...Array.from(new Set(data.stats.map((m) => m.url_page_type))),
    ];

    // shelf_typeでグループ化し、表示数で降順にソート
    const groups = new Map<string, typeof metrics>();
    metrics
      .sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown))
      .forEach((metric) => {
        const key = metric.shelf_type;
        if (!groups.has(key)) {
          groups.set(key, []);
        }
        groups.get(key)!.push(metric);
      });

    // 各グループ内でも表示数順にソート
    groups.forEach((groupMetrics, key) => {
      groups.set(
        key,
        groupMetrics.sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown)),
      );
    });

    // タブ内の指標の最大値を計算
    const maxVals = columns.reduce(
      (acc, col) => {
        const values = metrics.map((m) => {
          if (col.id === 'revenue') {
            return safeNumber(m.revenue);
          }
          return safeNumber(m[col.id]);
        });
        acc[col.id] = Math.max(0, ...values);
        return acc;
      },
      {} as Record<string, number>,
    );

    // グループ自体も表示数の合計で降順にソート
    const sortedGroups = new Map(
      Array.from(groups.entries()).sort((a, b) => {
        const aTotal = a[1].reduce((sum, m) => sum + safeNumber(m.shown), 0);
        const bTotal = b[1].reduce((sum, m) => sum + safeNumber(m.shown), 0);
        return bTotal - aTotal;
      }),
    );

    return {
      pageTypes: types,
      shelfTypeGroups: sortedGroups,
      maxValues: maxVals,
    };
  }, [data, selectedPageType, startDate, endDate]);

  const handleShelfTypeExpand = (shelfType: string) => {
    setExpandedTypes((prev) => {
      const next = new Set(prev);
      if (next.has(shelfType)) {
        next.delete(shelfType);
      } else {
        next.add(shelfType);
      }
      return next;
    });
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography color="error">
          データの読み込み中にエラーが発生しました
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <CustomPageTabs
          pageTypes={pageTypes}
          selectedPageType={selectedPageType}
          onChange={(_, value) => onPageTypeChange(value)}
          metrics={[]}
          startDate={startDate}
          endDate={endDate}
        />
      </Box>

      <TableContainer
        sx={{
          maxHeight: 600,
          overflow: 'auto',
          backgroundColor: 'white',
          borderRadius: 1,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  minWidth: 300,
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  zIndex: 3,
                  borderRight: '1px solid',
                  borderRightColor: 'divider',
                }}
              >
                シェルフ種別 / タグライン
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="right"
                  sx={{
                    minWidth: 100,
                    whiteSpace: 'nowrap',
                    backgroundColor: 'white',
                    zIndex: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 0.5,
                    }}
                  >
                    {column.label}
                    <Tooltip title={column.tooltip} arrow placement="top">
                      <Info size={16} color="#666" />
                    </Tooltip>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(shelfTypeGroups.entries()).map(
              ([shelfType, metrics]) => (
                <ShelfTypeGroup
                  key={shelfType}
                  shelfTypeKey={shelfType}
                  metrics={metrics}
                  maxValues={maxValues}
                  isExpanded={expandedTypes.has(shelfType)}
                  onExpand={handleShelfTypeExpand}
                  startDate={startDate}
                  endDate={endDate}
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShelfPerformance;
