import { ColumnDefinition } from './types';
import { safeNumber } from './utils';

export const columns: ColumnDefinition[] = [
  {
    id: 'shown',
    label: '表示数',
    tooltip: '期間中にユーザーの画面に表示された回数',
    format: (value: number | null | undefined) =>
      safeNumber(value).toLocaleString(),
  },
  {
    id: 'scroll',
    label: 'スクロール数',
    tooltip: 'シェルフまでスクロールされた回数',
    format: (value: number | null | undefined) =>
      safeNumber(value).toLocaleString(),
  },
  {
    id: 'scrollr',
    label: 'スクロール率',
    tooltip: '表示数に対するスクロール数の割合',
    format: (value: number | null | undefined) =>
      `${safeNumber(value).toFixed(1)}%`,
  },
  {
    id: 'click',
    label: 'クリック数',
    tooltip: 'ユーザーがコンポーネントをクリックした回数',
    format: (value: number | null | undefined) =>
      safeNumber(value).toLocaleString(),
  },
  {
    id: 'clickr',
    label: 'クリック率',
    tooltip: '表示数に対するクリック数の割合',
    format: (value: number | null | undefined) =>
      `${safeNumber(value).toFixed(1)}%`,
  },
  {
    id: 'cv',
    label: 'CV数',
    tooltip: '商品購入などのコンバージョンが発生した数',
    format: (value: number | null | undefined) =>
      safeNumber(value).toLocaleString(),
  },
  {
    id: 'cvr',
    label: 'CVR',
    tooltip: 'クリック数に対するコンバージョン数の割合 (CV数÷クリック数)',
    format: (value: number | null | undefined) =>
      `${safeNumber(value).toFixed(1)}%`,
  },
  {
    id: 'revenue',
    label: '月間換算売上',
    tooltip: '1CVあたり10,000円で計算した月間の想定売上額（期間の日数で調整）',
    format: (value: number | null | undefined) =>
      `¥${Math.round(safeNumber(value)).toLocaleString()}`,
  },
];
