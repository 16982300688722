interface PageTypeInfo {
  label: string;
  description: string;
}

export const getPageTypeInfo = (pageType: string): PageTypeInfo => {
  const pageTypeMap: Record<string, PageTypeInfo> = {
    // 既存のページタイプ
    top: {
      label: 'トップページ',
      description: 'ECサイトのメインページ',
    },
    pdp: {
      label: '商品詳細',
      description: '商品の詳細情報ページ',
    },
    list: {
      label: '検索結果',
      description: '商品検索・一覧ページ',
    },
    brand: {
      label: 'ブランド',
      description: 'ブランドページ',
    },
    'shop(incl.brand)': {
      label: 'ブランド',
      description: 'ブランドページ',
    },
    other: {
      label: 'その他',
      description: 'その他のページタイプ',
    },

    // 新規追加のページタイプ
    cart_show: {
      label: 'カート',
      description: 'ショッピングカートページ',
    },
    favorite_list: {
      label: 'お気に入り',
      description: 'お気に入り商品一覧ページ',
    },
    mypage: {
      label: 'マイページ',
      description: '会員情報・各種設定ページ',
    },
    order_complete: {
      label: '注文完了',
      description: '注文完了・サンクスページ',
    },
    order_history: {
      label: '注文履歴',
      description: '過去の注文履歴一覧ページ',
    },
    top_all: {
      label: 'トップ（すべて）',
      description: 'トップページ',
    },
    top_kids: {
      label: 'キッズトップ',
      description: 'キッズカテゴリのメインページ',
    },
    top_women: {
      label: 'レディーストップ',
      description: 'レディースカテゴリのメインページ',
    },
    top_men: {
      label: 'メンズトップ',
      description: 'メンズカテゴリのメインページ',
    },
    info: {
      label: 'インフォメーション',
      description: 'お知らせ・ヘルプページ',
    },
    news: {
      label: 'ニュース',
      description: 'ニュース・プレスリリースページ',
    },
    shop: {
      label: 'ショップ',
      description: 'ブランド・ショップ別商品一覧ページ',
    },
    ttop: {
      label: 'トップ',
      description: 'トップオブトップページ',
    },
    content: {
      label: 'コンテンツ',
      description: 'セールLPページ・キャンペーンページ等',
    },
    ranking: {
      label: 'ランキング',
      description: '人気商品ランキングページ',
    },
    category_search: {
      label: 'カテゴリ検索',
      description: 'カテゴリ商品検索ページ',
    },
    history: {
      label: '閲覧履歴',
      description: '閲覧履歴ページ',
    },
  };

  return (
    pageTypeMap[pageType] ?? {
      label: pageType,
      description: '',
    }
  );
};

// ラベルのみを取得する便利関数
export const getPageTypeLabel = (pageType: string): string => {
  return getPageTypeInfo(pageType).label;
};

// 説明のみを取得する便利関数
export const getPageTypeDescription = (pageType: string): string => {
  return getPageTypeInfo(pageType).description;
};
